import React, { useEffect, useState } from "react";
import { unstable_HistoryRouter as BrowserRouter, Route, Routes } from "react-router-dom";
import { MyContext } from "./Context";
import LocalStorage from "./utils/LocalStorage";
import Login from "./layouts/login/Login";
import LandingPage from "./landingpage/LandingPage";
import PrivateRoutes from "./routes/PrivateRoutes";
import NotAllowedPage from "./layouts/NotAllowedPage";
import { initSocketConnection } from "./utils/socketHandler";
import ForgotPassword from "./layouts/forgotPassword/ForgotPassword";
import MockupInterview from "./landingpage/components/MockupInterview";
import EmployerRegistration from "./landingpage/pages/EmployerRegistration";
import EmployerDashboard from "./landingpage/pages/EmployerDashboard";
import IntervieweeRegistration from "./landingpage/pages/interviewee/IntervieweeRegistration";
import IntervieweeLogin from "./landingpage/pages/interviewee/IntervieweeLogin";
import IntervieweeDashboard from "./landingpage/pages/interviewee/IntervieweeDashboard";
import IntervieweeUpload from "./landingpage/pages/interviewee/IntervieweeUpload";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SystemTestPage from "./components/SystemTestPage";
import CandidateInterviewPage from "./views/CandidateInterviewPage/CandidateInterviewPage";
import SearchJob from "./views/individual/SearchJob";
import JobDescriptionDetails from "./views/individual/JobDescriptionDetails";
import SearchResults from "./views/individual/SearchResults";
import AccountSettings from "./views/individual/settings/AccountSettings";
import EmployerHomePage from "./views/employer/EmployerHomePage";
import ProfileSection from "./views/individual/settings/AccountInfo";
import AssessmentMain from "./views/individual/assessment/AssessmentMain";
import AssessmentStart from "./views/individual/assessment/AssessmentStart";
import AssessmentConfirmation from "./views/individual/assessment/AssessmentConfirmationForm";
import EnvironmentSetup from "./views/individual/assessment/AssessmentEnvironmentSetup";
import AssessmentTestPage from "./views/individual/assessment/AssessmmentTestPage";
import IndividualDashboard from "./views/individual/dashboard/IndividualDashboard";
import Level2Page from "./views/individual/assessment/Level2Page";
import ReportCardPage from "./views/individual/assessment/ReportCardPage";
import AssessmentDetailPage from './views/individual/assessment/AssessmentDetailPage'
import history from "./utils/useHistory.js";

function App() {
  //Global context states
  const [globaldata, setGlobaldata] = useState({
    logo_url: "",
    sales: false,
    bills: false,
  });
  const [resumeInfo, setResumeInfo] = useState(null);
  
  useEffect(() => {
    let userData = LocalStorage.getUserData();
    if (userData != null) {
      setGlobaldata((prev) => ({ ...prev, logo_url: userData.logo_url }));
      initSocketConnection();
    } else {
      setGlobaldata((prev) => ({ ...prev, logo_url: "" }));
    }
  }, []);

  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <MyContext.Provider value={{ globaldata, setGlobaldata,resumeInfo, setResumeInfo }}>
          <BrowserRouter history={history}>
            {LocalStorage.getAccessToken() ? (
              <PrivateRoutes />
            ) : (
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/mockup-interview" element={<MockupInterview />} />
                <Route
                  path="/employer-registration"
                  element={<EmployerRegistration />}
                />
                <Route
                  path="/employer-dashboard"
                  element={
                    <PrivateRoutes>
                      <EmployerDashboard />
                    </PrivateRoutes>
                  }
                />
                <Route
                  path="/interviewee-registration"
                  element={<IntervieweeRegistration />}
                />
                <Route
                  path="/interviewee-login"
                  element={<IntervieweeLogin />}
                />
                <Route
                  path="/interviewee-dashboard"
                  element={<IntervieweeDashboard />}
                />
                <Route
                  path="/interviewee-upload"
                  element={<IntervieweeUpload />}
                />
                <Route path="/search" element={<SearchJob />} /> 
                <Route path="/account-info" element={<ProfileSection/>}/>
                <Route path="/job-description-details/:id" element={<JobDescriptionDetails />} />
                <Route path="/results" element={<SearchResults />} />
                <Route path="/account-settings" element={<AccountSettings />} />
                <Route path="/employer-homepage" element={<EmployerHomePage />} />
                <Route path="/assessment-main" element={<AssessmentMain />} />
                <Route path="/assessment-start" element={<AssessmentStart />} />
                <Route path="/assessment-confirmation-form" element={<AssessmentConfirmation />} />
                <Route path='/assessment-environment-setup' element={<EnvironmentSetup />} />
                <Route path="/assessment-test-page" element={<AssessmentTestPage />} />
                <Route path="/individual-dashboard" element={<IndividualDashboard />} />
                <Route path="/level-2" element={<Level2Page />} />
                <Route path="/report-card" element={<ReportCardPage />} />
                <Route path="/assessment/:assessmentId" element={<AssessmentDetailPage />} />
                      {/* <Route path="/candidate/system-test" element={<SystemTestPage />} />
                      <Route path="/candidate/interview" element={<CandidateInterviewPage />} /> */}
                <Route path="*" element={<NotAllowedPage />} />
              </Routes>
            )}
          </BrowserRouter>
        </MyContext.Provider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;

// import React, { useEffect, useState } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { MyContext } from "./Context";
// import LocalStorage from "./utils/LocalStorage";
// import Login from "./layouts/login/Login";
// import LandingPage from "./landingpage/LandingPage";
// import PrivateRoutes from "./routes/PrivateRoutes";
// import NotAllowedPage from "./layouts/NotAllowedPage";
// import { initSocketConnection } from "./utils/socketHandler";
// import ForgotPassword from "./layouts/forgotPassword/ForgotPassword";
// import MockupInterview from "./landingpage/components/MockupInterview";
// import EmployerRegistration from "./landingpage/pages/EmployerRegistration";
// import EmployerDashboard from "./landingpage/pages/EmployerDashboard";
// import IntervieweeRegistration from "./landingpage/pages/interviewee/IntervieweeRegistration";
// import IntervieweeLogin from "./landingpage/pages/interviewee/IntervieweeLogin";
// import IntervieweeDashboard from "./landingpage/pages/interviewee/IntervieweeDashboard";
// import IntervieweeUpload from "./landingpage/pages/interviewee/IntervieweeUpload";
// import { GoogleOAuthProvider } from "@react-oauth/google";
// import BreadcrumbsComponent from "../../../frontend-latest/src/v2/components/table/Breadcrumbs"; // Import the Breadcrumbs component

// function App() {
//   const [globaldata, setGlobaldata] = useState({
//     logo_url: "",
//     sales: false,
//     bills: false,
//   });

//   useEffect(() => {
//     let userData = LocalStorage.getUserData();
//     if (userData != null) {
//       setGlobaldata((prev) => ({ ...prev, logo_url: userData.logo_url }));
//       initSocketConnection();
//     } else {
//       setGlobaldata((prev) => ({ ...prev, logo_url: "" }));
//     }
//   }, []);

//   return (
//     <div>
//       <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
//         <MyContext.Provider value={{ globaldata, setGlobaldata }}>
//           <BrowserRouter>
//             {LocalStorage.getAccessToken() ? (
//               <PrivateRoutes />
//             ) : (
//               <>
//                 <BreadcrumbsComponent /> {/* Breadcrumbs for public routes */}
//                 <Routes>
//                   <Route path="/" element={<LandingPage />} />
//                   <Route path="/login" element={<Login />} />
//                   <Route path="/forgot-password" element={<ForgotPassword />} />
//                   <Route path="/mockup-interview" element={<MockupInterview />} />
//                   <Route path="/employer-registration" element={<EmployerRegistration />} />
//                   <Route path="/employer-dashboard" element={
//                     <PrivateRoutes>
//                       <EmployerDashboard />
//                     </PrivateRoutes>
//                   } />
//                   <Route path="/interviewee-registration" element={<IntervieweeRegistration />} />
//                   <Route path="/interviewee-login" element={<IntervieweeLogin />} />
//                   <Route path="/interviewee-dashboard" element={<IntervieweeDashboard />} />
//                   <Route path="/interviewee-upload" element={<IntervieweeUpload />} />
//                   <Route path="*" element={<NotAllowedPage />} />
//                 </Routes>
//               </>
//             )}
//           </BrowserRouter>
//         </MyContext.Provider>
//       </GoogleOAuthProvider>
//     </div>
//   );
// }

// export default App;


// import React, { useEffect, useState } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { MyContext } from "./Context";
// import LocalStorage from "./utils/LocalStorage";
// import Login from "./layouts/login/Login";
// import LandingPage from "./landingpage/LandingPage";
// import PrivateRoutes from "./routes/PrivateRoutes";
// import NotAllowedPage from "./layouts/NotAllowedPage";
// import { initSocketConnection } from "./utils/socketHandler";
// import ForgotPassword from "./layouts/forgotPassword/ForgotPassword";
// import MockupInterview from "./landingpage/components/MockupInterview";
// import EmployerRegistration from "./landingpage/pages/EmployerRegistration";
// import EmployerDashboard from "./landingpage/pages/EmployerDashboard";
// import IntervieweeRegistration from "./landingpage/pages/interviewee/IntervieweeRegistration";
// import IntervieweeLogin from "./landingpage/pages/interviewee/IntervieweeLogin";
// import IntervieweeDashboard from "./landingpage/pages/interviewee/IntervieweeDashboard";
// import IntervieweeUpload from "./landingpage/pages/interviewee/IntervieweeUpload";
// import { GoogleOAuthProvider } from "@react-oauth/google";
// import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import ThemeProvider

// function App() {
//   const [globaldata, setGlobaldata] = useState({
//     logo_url: "",
//     sales: false,
//     bills: false,
//   });

//   useEffect(() => {
//     let userData = LocalStorage.getUserData();
//     if (userData != null) {
//       setGlobaldata((prev) => ({ ...prev, logo_url: userData.logo_url }));
//       initSocketConnection();
//     } else {
//       setGlobaldata((prev) => ({ ...prev, logo_url: "" }));
//     }
//   }, []);

//   const theme = createTheme(); // Create a theme

//   return (
//     <div>
//       <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
//         <MyContext.Provider value={{ globaldata, setGlobaldata }}>
//           <ThemeProvider theme={theme}> {/* Wrap with ThemeProvider */}
//             <BrowserRouter>
//               {LocalStorage.getAccessToken() ? (
//                 <PrivateRoutes />
//               ) : (
//                 <Routes>
//                   <Route path="/" element={<LandingPage />} />
//                   <Route path="/login" element={<Login />} />
//                   <Route path="/forgot-password" element={<ForgotPassword />} />
//                   <Route path="/mockup-interview" element={<MockupInterview />} />
//                   <Route path="/employer-registration" element={<EmployerRegistration />} />
//                   <Route path="/employer-dashboard" element={
//                     <PrivateRoutes>
//                       <EmployerDashboard />
//                     </PrivateRoutes>
//                   } />
//                   <Route path="/interviewee-registration" element={<IntervieweeRegistration />} />
//                   <Route path="/interviewee-login" element={<IntervieweeLogin />} />
//                   <Route path="/interviewee-dashboard" element={<IntervieweeDashboard />} />
//                   <Route path="/interviewee-upload" element={<IntervieweeUpload />} />
//                   <Route path="*" element={<NotAllowedPage />} />
//                 </Routes>
//               )}
//             </BrowserRouter>
//           </ThemeProvider>
//         </MyContext.Provider>
//       </GoogleOAuthProvider>
//     </div>
//   );
// }

// export default App;
