import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/Logo_Hyr_Orange_png.png';
import CommonApi from '../../apis/CommonApi';
import LocalStorage from '../../utils/LocalStorage';

const IndividualHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const isDropdownOpen = Boolean(anchorEl);
  const navigate = useNavigate();

  // Fetch user data from LocalStorage
  const user = LocalStorage.getUserData();
  const userName = user?.user?.name || 'User';
  const userEmail = user?.user?.email || 'No Email';
  const profilePicture = user?.user?.profileImage;

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
    handleDropdownClose();
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  const handleLogout = async () => {
    try {
      const response = await CommonApi.Logout();
      if (response.status === 200) {
        LocalStorage.clearAll();
        window.location.href = '/login'; // Use full-page reload
      } else {
        console.error('Logout failed', response);
      }
    } catch (error) {
      console.error('Logout error', error);
    }
  };

  return (
    <AppBar position="fixed" color="inherit" elevation={1} sx={{ top: 0 }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Logo */}
        <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={Logo} alt="OptHyr" style={{ height: '55px', marginRight: '10px' }} />
          {/* <h1 style={{fontWeight:"500",fontSize:"1.75rem",fontFamily:"Poppins"}}>OptHyr</h1> */}
        </Box>

        {/* Navigation Links */}
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Button component={Link} to="/individual-dashboard" color="inherit">
            Dashboard
          </Button>
          <Button component={Link} to="/assessment-main" color="inherit">
            Assessment
          </Button>
        </Box>

        {/* User Profile Dropdown */}
        <IconButton color="inherit" onClick={handleDropdownOpen} aria-label="User profile">
          <Avatar
            src={profilePicture || ''}
            alt={userName}
            sx={{ bgcolor: profilePicture ? 'orange' : 'transparent' }}
          >
            {userName.charAt(0)}
          </Avatar>
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={isDropdownOpen}
          onClose={handleDropdownClose}
          PaperProps={{
            style: { width: 200, padding: '8px 0' },
            elevation: 3,
          }}
        >
          {/* User Name */}
          <MenuItem disabled>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
              {userName}
            </Typography>
          </MenuItem>

          {/* User Email */}
          {/* <MenuItem disabled>
            <Typography variant="body2" sx={{ color: userEmail === 'No Email' ? 'error.main' : 'text.secondary' }}>
              {userEmail}
            </Typography>
          </MenuItem> */}

          {/* Account Settings */}
          <MenuItem
            component={Link}
            to="/account-settings"
            onClick={handleDropdownClose}
            sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
          >
            <Typography variant="body1">Account Settings</Typography>
          </MenuItem>

          {/* Logout */}
          <MenuItem
            onClick={handleLogoutDialogOpen}
            sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
          >
            <Typography variant="body1">Log Out</Typography>
          </MenuItem>
        </Menu>
      </Toolbar>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutDialogClose}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="secondary" autoFocus>
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default IndividualHeader;
