import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { MyContext } from "../../Context";
import {
  Grid,
  Link,
  Box,
  Button,
  TextField,
  Divider,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { LoginStyles } from "./LoginStyles";
import Text from "../../components/customText/Text";
import { isValid, validate_emptyField } from "../../components/Validation";
import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
import { LoadingButton } from "@mui/lab";
import eye from "../../assets/svg/eye.svg";
import inVisible from "../../assets/svg/NotVisible.svg";
import GoogleLogo from "../../assets/svg/google-logo.svg";
import CommonApi from "../../apis/CommonApi";
import LocalStorage from "../../utils/LocalStorage";
import IllustrationImage from "../../assets/svg/illustration.png";
import Logo from "../../assets/svg/TO Logo.svg";
import { toast } from "react-toastify";

function Login() {
  const classes = LoginStyles();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width:900px)");
  const { setGlobaldata } = useContext(MyContext);
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    fullName: "",
    mobileNumber: "",
    userType: "individual",
    workStatus: "",
  });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState(null);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  const validateForm = (data, isSignUp, isOtpSent) => {
    const errors = {
      email: validate_emptyField(data.email),
      password: validate_emptyField(data.password),
    };

    if (isSignUp && !isOtpSent) {
      errors.fullName = validate_emptyField(data.fullName);
      errors.mobileNumber = validate_emptyField(data.mobileNumber);
      errors.userType = validate_emptyField(data.userType);

      if (data.userType === "individual") {
        errors.workStatus = validate_emptyField(data.workStatus);
      }
    }

    return errors;
  };

  const handleSendOtp = async () => {
    try {
      setLoading(true);
      const response = await CommonApi.sendEmailOtp(formData.email);
      if (response.success) {
        if(response.redirect){
          setFormData({
            email: "",
            password: "",
            fullName: "",
            mobileNumber: "",
            userType: "individual",
            workStatus: "",
          })
          addErrorMsg(response.message)
          navigate('/login')
          return;
        }
        setIsOtpSent(true);
      }
      setLoading(false);
      setIsResendDisabled(true);
      setTimer(60);

      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(countdown);
            setIsResendDisabled(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } catch (error) {
      setLoading(false);
      addErrorMsg("Failed to send OTP. Please try again.");
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    handleValidate(e);
  };

  const handleValidate = (e) => {
    let input = e.target;
    let newError = { ...error };
    newError[input.name] = validate_emptyField(input.value);
    setError(newError);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validateForm(formData, isSignUp, isOtpSent);

    if (!isValid(errors)) {
      setError(errors);
      return;
    }

    setLoading(true);
    try {
      if (isSignUp) {
        if (!isOtpSent) {
          await handleSendOtp();
        } else {
          await verifyOtpAndRegister();
        }
      } else {
        await handleLogin();
      }
    } catch (error) {
      addErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    const response = await CommonApi.loginIndividual(formData);
    if (response.status === 200 || response.status === 201) {
      const { token, userId, role, user } = response.data;
      handleSuccessfulLogin(token, userId, role, user);
    } else {
      throw new Error(response.data.message);
    }
  };

  const verifyOtpAndRegister = async () => {
    const verified = await CommonApi.verifyEmailOtp(formData.email, Number(otp));
    if (!verified.success) {
      throw new Error("OTP verification failed");
    }

    const response = await CommonApi.registerIndividual(formData);
    if (response.status === 200 || response.status === 201) {
      setIsSignUp(false);
      addSuccessMsg(response.message)
      navigate("/login");
    }
  };

  const handleSuccessfulLogin = async (token, userId, role, user) => {
    LocalStorage.setAccessToken(token);
    LocalStorage.setUserData({ token, userId, role, user });
    addSuccessMsg("Successfully logged in");

    if (role === "individual") {
      await navigateBasedOnProfileCompletion();
    } else {
      navigate("/dashboard");
    }
    window.location.reload();
  };

  const navigateBasedOnProfileCompletion = async () => {
    try {
      const profileResponse = await CommonApi.getProfileCompletion();
      const profileCompletion = profileResponse.profileCompletion;

      if (profileCompletion >= 75) {
        navigate("/assessment-main");
      } else {
        navigate("/account-settings");
      }
    } catch (error) {
      console.error("Error fetching profile completion:", error);
      navigate("/account-settings");
    }
  };

  const passwordVisible = () => {
    setVisible(!isVisible);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { code } = tokenResponse;
        const response = await CommonApi.googleLogin({ code });
        if (response && response.token) {
          const { token, userId, role, user } = response;
          handleSuccessfulLogin(token, userId, role, user);
        } else {
          addErrorMsg("Unexpected response from server.");
        }
      } catch (error) {
        addErrorMsg("Google login failed. Try again.");
      }
    },
    onError: () => {
      addErrorMsg("Google login failed");
    },
    scope: "openid profile email",
    flow: "auth-code",
  });

  return (
    <div className={classes.mainContainer}>
      <Box className={classes.innerContainer}>
        <Grid container>
          {isLargeScreen && (
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.leftContainer}
            >
              <Box className={classes.leftContent}>
                <Text variant="h3" className={classes.headerText}>
                  Simplify hiring process with <b>OptHyr</b>.
                </Text>
                <Text className={classes.subText}>
                  Simplify your hiring process with our end-to-end solution.
                </Text>
                <img
                  src={IllustrationImage}
                  alt="Illustration"
                  className={classes.illustration}
                />
              </Box>
            </Grid>
          )}
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.loginFormBox}
          >
            <Box
              style={{ width: "100%", maxWidth: "400px", textAlign: "center" }}
            >
              <img src={Logo} alt="Logo" className={classes.logo} />
              <form onSubmit={handleSubmit}>
                <Text variant="h5" className={classes.welText}>
                  {isSignUp ? "Create your account" : "Welcome Back"}
                </Text>
                <Text className={classes.paraText}>
                  {isSignUp
                    ? "Sign up to start hiring smarter."
                    : "Please login to your account"}
                </Text>

                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                  {isSignUp && !isOtpSent && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="fullName"
                          label="Full Name"
                          fullWidth
                          name="fullName"
                          value={formData.fullName}
                          onChange={changeHandler}
                          error={!!error.fullName}
                          helperText={error.fullName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="mobileNumber"
                          label="Mobile Number"
                          fullWidth
                          name="mobileNumber"
                          value={formData.mobileNumber}
                          onChange={changeHandler}
                          error={!!error.mobileNumber}
                          helperText={error.mobileNumber}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>User Type</InputLabel>
                          <Select
                            required
                            name="userType"
                            value={formData.userType}
                            onChange={changeHandler}
                          >
                            <MenuItem value="individual">Individual</MenuItem>
                            <MenuItem value="employer">Employer</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> */}
                      {formData.userType === "individual" && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                          <TextField
                            select
                            required
                            fullWidth
                            label="Work Status"
                            name="workStatus"
                            value={formData.workStatus}
                            onChange={changeHandler}
                            variant="outlined" // You can choose 'filled' or 'standard' as well
                          >
                            <MenuItem value="Experienced">
                              I'm experienced
                            </MenuItem>
                            <MenuItem value="Fresher">
                              I'm a fresher
                            </MenuItem>
                          </TextField>
                          </FormControl>
                        </Grid>
                      )}
                    </>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      required
                      id="email"
                      label="Email Address"
                      fullWidth
                      name="email"
                      value={formData.email}
                      onChange={changeHandler}
                      error={!!error.email}
                      helperText={error.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="password"
                      label="Password"
                      fullWidth
                      type={!isVisible ? "password" : "text"}
                      name="password"
                      onChange={changeHandler}
                      value={formData.password}
                      error={!!error.password}
                      helperText={error.password}
                      InputProps={{
                        endAdornment: (
                          <Box
                            component="span"
                            onClick={passwordVisible}
                            style={{ cursor: "pointer" }}
                          >
                            {isVisible ? (
                              <img src={eye} alt="Visible" />
                            ) : (
                              <img src={inVisible} alt="Hidden" />
                            )}
                          </Box>
                        ),
                      }}
                    />
                  </Grid>

                  {isSignUp && isOtpSent && (
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="otp"
                        label="Enter OTP"
                        fullWidth
                        name="otp"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>

                <LoadingButton
                  loading={loading}
                  onClick={handleSubmit}
                  variant="contained"
                  type="submit"
                  className={classes.loginButton}
                  mt={2}
                >
                  {isSignUp ? (isOtpSent ? "Sign Up" : "Send OTP") : "Login"}
                </LoadingButton>

                {isSignUp && isOtpSent && (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={handleSendOtp}
                    disabled={isResendDisabled}
                    style={{ marginTop: "10px" }}
                  >
                    {isResendDisabled
                      ? `Resend in ${timer}s`
                      : "Resend OTP"}
                  </Button>
                )}

                <Box mt={3} textAlign="center">
                  <span>
                    {isSignUp
                      ? "Already have an account?"
                      : "Don't have an account?"} {" "}
                    <Link
                      component="button"
                      onClick={() => setIsSignUp(!isSignUp)}
                      className={classes.toggleText}
                    >
                      {isSignUp ? "Log In" : "Sign Up"}
                    </Link>
                  </span>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Login;
