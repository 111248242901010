import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation, useNavigate } from 'react-router-dom';

const EnvironmentSetup = ({ experience }) => {
    const [isPreparing, setIsPreparing] = useState(true);
    const duration = experience === "1" ? "30 mins" : experience === "5" ? "60 mins" : "45 mins";
    // const questionCount = experience === "1" ? "10 questions" : experience === "5" ? "20 questions" : "15 questions";
    const navigate = useNavigate();
    const location = useLocation()
    const { assessmentId,questions } = location.state || {};
    useEffect(() => {
        setTimeout(() => {
            setIsPreparing(false);
        }, 3000); // Simulate preparation time
    }, []);

    const handleGoToTest = () => {
        navigate('/assessment-test-page',{state:{assessmentId,questions}});
    };

    return (
        <Container maxWidth="xl" sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2c3e50', mb: 2 }}>
                    Environment Setup
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Test duration: {duration} | No. of questions: {questions?.length}
                </Typography>
                {isPreparing ? (
                    <>
                        <Typography variant="body2" sx={{ color: '#2c3e50', mb: 1 }}>
                            <CircularProgress size={24} /> Preparing your environment
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="body2" sx={{ color: '#2c3e50', mb: 1 }}>
                            <CheckCircleIcon color="success" /> Your environment is ready
                        </Typography>
                        <Button variant="contained" sx={{backgroundColor: '#ff2600', borderRadius: '12px', mt: 3}} onClick={handleGoToTest}>
                            Go to Test
                        </Button>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default EnvironmentSetup;
