import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 10, mb: 6 }}>
      <Paper
        elevation={3}
        sx={{
          borderRadius: 4,
          p: 4,
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3 }}>
            Terms of Service
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            By accessing or using OptHyr, you acknowledge that you have read,
            understood, and agree to these Terms, including our Privacy Policy. If
            you do not agree to these Terms, you may not use the platform.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            2. User Conduct
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            By participating in interviews or assessments on OptHyr, you agree to
            the following:
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8, pl: 2 }}>
            <strong>Original Work:</strong> You will provide only original answers
            and will not copy or refer to code or content from any source,
            including colleagues, websites, or third-party tools, such as
            AI-generated solutions.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8, pl: 2 }}>
            <strong>Integrity:</strong> You will refrain from accessing
            unauthorized resources, websites, or tools to assist with your answers
            during the interview process.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            Violation of these terms may result in disqualification from the
            assessment or termination of your access to the OptHyr.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            3. Prohibited Activities
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            You agree not to engage in any activity that:
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8, pl: 2 }}>
            - Compromises the fairness, integrity, or confidentiality of the
            interview process.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8, pl: 2 }}>
            - Violates intellectual property rights, including the unauthorized
            use of content or code.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8, pl: 2 }}>
            - Circumvents security measures implemented on the Platform.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            4. Intellectual Property
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            All content, including questions, assessments, and materials provided
            on the Platform, is the exclusive property of OptHyr. You may not
            reproduce, distribute, or share this content in any form without prior
            written consent.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            5. Privacy Policy
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            By using the Platform, you agree to our Privacy Policy, which outlines
            how we collect, use, and protect your personal information.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            6. Disclaimer of Warranties
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            The Platform is provided “as is” without warranties of any kind.
            OptHyr does not guarantee uninterrupted access, error-free
            assessments, or specific outcomes from using the Platform.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            7. Limitation of Liability
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            OptHyr shall not be liable for any indirect, incidental, or
            consequential damages arising from your use of the Platform.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            8. Changes to Terms
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            OptHyr reserves the right to modify these Terms at any time. Updated
            Terms will be effective upon posting, and continued use of the
            Platform constitutes acceptance of the revised Terms.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            9. Governing Law
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            These Terms shall be governed by the laws of Indian Jurisdiction. Any
            disputes arising from these Terms will be subject to the exclusive
            jurisdiction of the courts of Indian Jurisdiction.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>
            10. Contact Us
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
            For questions or concerns about these Terms, please contact us at{" "}
            <a href="mailto:info@techoptima.ai" style={{ color: "#ff2600" }}>
              info@techoptima.ai
            </a>
            .
          </Typography>

          <Typography variant="body1" sx={{ mt: 4, lineHeight: 1.8 }}>
            By clicking “I Agree” or accessing the Platform, you acknowledge that
            you have read, understood, and agree to these Terms of Service.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsOfService;
