// import React, { useEffect, useState } from 'react';
// import { Box, Typography, TextField, Link, IconButton, Divider, CircularProgress } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import CheckIcon from '@mui/icons-material/Check';
// import PhoneIcon from '@mui/icons-material/Phone';
// import CommonApi from '../../../apis/CommonApi';

// const LoginInfo = () => {
//     const [accountInfo, setAccountInfo] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchAccountInfo = async () => {
//             try {
//                 const data = await CommonApi.getAccountInformation();
//                 setAccountInfo(data);
//                 setLoading(false);
//             } catch (err) {
//                 console.error("Error fetching account information:", err);
//                 setError("Failed to load account information.");
//                 setLoading(false);
//             }
//         };
        
//         fetchAccountInfo();
//     }, []);

//     if (loading) {
//         return <CircularProgress />;
//     }

//     if (error) {
//         return <Typography color="error">{error}</Typography>;
//     }

//     return (
//         <Box>
//             <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
//                 Login info
//             </Typography>

//             {/* Account Email Section */}
//             <Box sx={{ mb: 2 }}>
//                 <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
//                     Account email
//                 </Typography>
//                 <TextField
//                     fullWidth
//                     variant="outlined"
//                     value={accountInfo?.email || ""}
//                     InputProps={{
//                         readOnly: true,
//                         endAdornment: (
//                             <IconButton edge="end" disabled>
//                                 <EditIcon color="primary" />
//                             </IconButton>
//                         ),
//                         sx: {
//                             bgcolor: '#f5f6f7',
//                             borderRadius: '8px',
//                         },
//                     }}
//                 />
//                 <Box display="flex" alignItems="center" gap={1} mt={1}>
//                     <CheckIcon color="primary" fontSize="small" />
//                     <Typography variant="body2" color="textSecondary">
//                         Confirmed
//                     </Typography>
//                 </Box>
//             </Box>

//             {/* Password Section */}
//             <Box sx={{ mb: 2 }}>
//                 <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
//                     Password
//                 </Typography>
//                 <TextField
//                     fullWidth
//                     type="password"
//                     variant="outlined"
//                     value="********"
//                     InputProps={{
//                         readOnly: true,
//                         endAdornment: (
//                             <IconButton edge="end">
//                                 <EditIcon color="primary" />
//                             </IconButton>
//                         ),
//                         sx: {
//                             bgcolor: '#f5f6f7',
//                             borderRadius: '8px',
//                         },
//                     }}
//                 />
//                 <Link href="#" variant="body2" color="primary" sx={{ mt: 1, display: 'block' }}>
//                     Edit Password
//                 </Link>
//             </Box>

//             <Divider sx={{ my: 2 }} />

//             {/* Recovery Phone Number Section */}
//             <Box display="flex" alignItems="start" gap={2}>
//                 <PhoneIcon color="action" />
//                 <Box>
//                     <Typography variant="body2" color="textSecondary">
//                         Recovery phone number
//                     </Typography>
//                     <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
//                         Your phone number can be used to recover your account.
//                     </Typography>
//                     <Link href="#" variant="body2" color="primary">
//                         Add Phone Number
//                     </Link>
//                 </Box>
//             </Box>
//         </Box>
//     );
// };

// export default LoginInfo;

import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, IconButton, Divider, Dialog, DialogTitle, DialogContent, Button, Paper, Link } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CommonApi from '../../../apis/CommonApi';

const LoginInfo = () => {
    const [accountInfo, setAccountInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    useEffect(() => {
        const fetchAccountInfo = async () => {
            try {
                const data = await CommonApi.getAccountInformation();
                setAccountInfo(data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching account information:", err);
                setError("Failed to load account information.");
                setLoading(false);
            }
        };
        
        fetchAccountInfo();
    }, []);

    const handleEditOpen = () => setEditOpen(true);
    const handleEditClose = () => setEditOpen(false);

    const handleSave = async () => {
        setError(null);
        setLoading(true);
      
        try {
          await CommonApi.updateEmailAndPassword({
            currentPassword,
            newPassword,
            newEmail: newEmail || accountInfo.email,
          });
      
          const updatedInfo = await CommonApi.getAccountInformation();
          setAccountInfo(updatedInfo);
          setEditOpen(false);
          setLoading(false);
        } catch (err) {
          console.error("Error updating account information:", err);
          setError("Failed to update account information. Please ensure your current password is correct.");
          setLoading(false);
        }
      };

    return (
        <Paper sx={{ p: 3, boxShadow: 3, borderRadius: 2, height: '100%' }}>
            <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                Login info
            </Typography>

            {/* Account Email Section */}
            <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                    Account email
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={accountInfo?.email || ""}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton edge="end" onClick={handleEditOpen}>
                                <EditIcon color="primary" />
                            </IconButton>
                        ),
                        sx: { bgcolor: '#f5f6f7', borderRadius: '8px' },
                    }}
                />
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                    <CheckIcon color="primary" fontSize="small" />
                    <Typography variant="body2" color="textSecondary">
                        Confirmed
                    </Typography>
                </Box>
            </Box>

            {/* Password Section */}
            <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                    Password
                </Typography>
                <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    value="********"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton edge="end" onClick={handleEditOpen}>
                                <EditIcon color="primary" />
                            </IconButton>
                        ),
                        sx: { bgcolor: '#f5f6f7', borderRadius: '8px' },
                    }}
                />
            </Box>

            {/* <Divider sx={{ my: 2 }} /> */}

            {/* Recovery Phone Number Section */}
            {/* <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ py: 1 }}>
                <Box display="flex" alignItems="start" gap={2}>
                    <PhoneIcon color="action" sx={{mt:0.5}} />
                    <Box>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                            Recovery phone number
                            <IconButton size="small" color="primary">
                                <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Your phone number can be used to recover your account.
                        </Typography>
                    </Box>
                </Box>
                <Button variant="text" color="primary" sx={{ textTransform: 'none' }}>
                    Add Phone Number
                </Button>
            </Box> */}

            {/* <Divider sx={{ my: 2 }} /> */}

            {/* Edit Dialog */}
            <Dialog open={editOpen} onClose={handleEditClose} maxWidth="sm" fullWidth>
                <DialogTitle>Edit Email and Password</DialogTitle>
                <DialogContent>
                    <TextField fullWidth variant="outlined" label="New Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} sx={{ mb: 2 }} />
                    <TextField fullWidth variant="outlined" label="Current Password" type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} sx={{ mb: 2 }} />
                    <TextField fullWidth variant="outlined" label="New Password" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} sx={{ mb: 2 }} />
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button onClick={handleEditClose} color="secondary" sx={{ mr: 1 }}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Paper>
    );
};

export default LoginInfo;


