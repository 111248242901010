import React from 'react';
import { Route } from 'react-router-dom';
import SearchJob from '../views/individual/SearchJob';
import SearchResults from '../views/individual/SearchResults';
import JobDescriptionDetails from '../views/individual/JobDescriptionDetails';
import AccountSettings from '../views/individual/settings/AccountSettings';
import EmailVerificationSuccess from '../views/individual/settings/EmailVerificationSuccess';
import VerifyEmail from '../views/individual/settings/VerifyEmail';
import AssessmentMain from '../views/individual/assessment/AssessmentMain';
import AssessmentStart from '../views/individual/assessment/AssessmentStart';
import AssessmentConfirmationForm from '../views/individual/assessment/AssessmentConfirmationForm';
import EnvironmentSetup from '../views/individual/assessment/AssessmentEnvironmentSetup';
import AssessmentTestPage from '../views/individual/assessment/AssessmmentTestPage';
import IndividualDashboard from '../views/individual/dashboard/IndividualDashboard';
import Level2Page from '../views/individual/assessment/Level2Page';
import ReportCardPage from '../views/individual/assessment/ReportCardPage';
import AssessmentDetailPage from '../views/individual/assessment/AssessmentDetailPage'
import { Navigate } from 'react-router-dom';
import TermsOfService from '../views/individual/assessment/TermsAndServices';
import VerifyAuth from '../layouts/login/VerifyAuth';
const IndividualRoutes = [
  {
    id: "individual",
    path: "/search",
    element: <SearchJob />,
    name: "Search Jobs",
    slug: "search",
    access: true,
  },
  {
    id: "individual",
    path: "/search-results",
    element: <SearchResults />,
    name: "Search Results",
    slug: "search-results",
    access: true,
  },
  {
    id: "individual",
    path: "/job-description",
    element: <JobDescriptionDetails />,
    name: "Job Description Details",
    slug: "job-description",
    access: true,
  },
  {
    id: "individual",
    path: "/account-settings",
    element: <AccountSettings />,
    name: "Account Settings",
    slug: "account-settings",
    access: true,
  },
  {
    id: "individual",
    path: "/verify-email-success",
    element: <EmailVerificationSuccess />,
    name: "Email Verification Success",
    slug: "email-verification-success",
    access: true,
  },
  {
    id: "individual",
    path: "/verify-email",
    element: <VerifyEmail />,
    name: "Verify Email",
    slug: "verify-email",
    access: true,
  },
  {
    id: "individual",
    path: "/assessment-main",
    element: <AssessmentMain />,
    name: "Assessment Main",
    slug: "assessment-main",
    access: true,
  },
  {
    id: "individual",
    path: "/assessment-start",
    element: <AssessmentStart />,
    name: "Assessment Start",
    slug: "assessment-start",
    access: true,
  },
  {
    id: "individual",
    path: "/assessment-confirmation-form",
    element: <AssessmentConfirmationForm />,
    name: "Assessment Confirmation Form",
    slug: "assessment-confirmation-form",
    access: true,
  },
  {
    id: "individual",
    path: "/assessment-environment-setup",
    element: <EnvironmentSetup />,
    name: "Assessment Environment Setup",
    slug: "assessment-environment-setup",
    access: true,
  },
  {
    id: "individual",
    path: "/assessment-test-page",
    element: <AssessmentTestPage />,
    name: "Assessment Test Page",
    slug: "assessment-test-page",
    access: true,
  },
  {
    id: "individual",
    path: "/individual-dashboard",
    element: <IndividualDashboard />,
    name: "Individual Dashboard",
    slug: "individual-dashboard",
    access: true,
  },
  {
    id: "individual",
    path: "/level-2",
    element: <Level2Page />,
    name: "Level 2 Assessment",
    slug: "level-2",
    access: true,
  },
  {
    id: "individual",
    path: "/report-card",
    element: <ReportCardPage />,
    name: "Report Card",
    slug: "report-card",
    access: true,
  },
  {
    id: "individual",
    path: "*",
    element: <Navigate to="/account-settings" replace />,
    name: "Redirect to Account Settings",
    slug: "redirect",
    access: true,
  },

  {
    id: "individual",
    path: "/assessment/:assessmentId",
    element: <AssessmentDetailPage />,
    name: "Assessment Detail Page",
    slug: "assessment-detail",
    access: true,
  },

  {
    id: "individual",
    path: "/terms-of-service",
    element: <TermsOfService />,
    name: "Assessment Detail Page",
    slug: "assessment-detail",
    access: true,
  },

  {
    id: "individual",
    path: "/verify-auth",
    element: <VerifyAuth />,
    name: "Verify Login",
    slug: "verify-auth",
    access: true,
  },

  // /terms-of-service

  
  // <Route path="/assessment/:assessmentId" element={<AssessmentDetailPage />} />
];

export default IndividualRoutes;
