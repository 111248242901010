import React from 'react';
import { useTheme } from '../context/ThemeContext'; // Import the custom ThemeContext
import { BorderBeam } from '../styles/magicui/BorderBeam'; // Ensure the correct import path
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const Pricing = () => {
  const { theme } = useTheme(); // Access the current theme
  const navigate = useNavigate();

  // Function to navigate for the Basic plan
  const handleBasicNavigation = () => {
    navigate('/login'); // Navigate to the /login page for Basic plan
  };

  const pricingPlans = [
    {
      name: 'Basic',
      price: 'Free',
      description: 'Ideal for small teams starting out with basic recruitment needs.',
      features: [
        'Basic Job Description Creation',
        'Resume Upload and Parsing',
        'Basic Job Matching',
        'Email Notifications',
        'Limited AI Interview Questions',
      ],
      buttonText: 'Start for Free',
      buttonStyle: 'bg-gray-700 hover:bg-gray-600 text-white cursor-pointer',
      onClick: handleBasicNavigation, // Only Basic has a clickable action
    },
    {
      name: 'Professional',
      price: '$99/month',
      description: 'Perfect for growing teams needing advanced AI assistance.',
      features: [
        'Advanced Job Description Templates',
        'AI-Powered Resume Parsing',
        'Enhanced Job Matching with Detailed Insights',
        'Interactive AI Interviews',
        'Priority Email and Candidate Notifications',
      ],
      buttonText: 'Coming Soon',
      buttonStyle: 'bg-gray-400 text-white cursor-not-allowed', // Disabled appearance
      onClick: null, // No action for this button
    },
    {
      name: 'Enterprise',
      price: '$199/month',
      description: 'Best for large enterprises requiring full platform capabilities and customization.',
      features: [
        'Unlimited Job Descriptions and Listings',
        'Full AI-Powered Resume Parsing and Matching',
        'Custom AI Interview Scripts',
        'Advanced Analytics and Reporting',
        'Dedicated Support and Integration Options',
      ],
      buttonText: 'Coming Soon',
      buttonStyle: 'bg-gray-400 text-white cursor-not-allowed', // Disabled appearance
      onClick: null, // No action for this button
    },
  ];

  return (
    <section
      id="pricing"
      className={`py-16 ${
        theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
      } transition-colors duration-300`}
    >
      <h2 className="text-4xl font-bold text-center mb-6">Flexible Pricing Plans</h2>
      <p
        className={`text-center mb-12 ${
          theme === 'dark' ? 'text-gray-400' : 'text-gray-700'
        }`}
      >
        Choose the plan that best fits your company's recruitment needs.
      </p>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4 relative">
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            className={`relative p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 ${
              theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'
            }`}
          >
            <BorderBeam
              size={250}
              duration={10}
              delay={index * 2} // Stagger delay for visual effect
              colorFrom="#ffaa40"
              colorTo="#9c40ff"
            />
            <h3 className="text-2xl font-semibold mb-4">{plan.name}</h3>
            <p className="text-4xl font-bold mb-4">{plan.price}</p>
            <p
              className={`${
                theme === 'dark' ? 'text-gray-400' : 'text-gray-700'
              } mb-6`}
            >
              {plan.description}
            </p>
            <ul className="mb-6 space-y-2">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span> {feature}
                </li>
              ))}
            </ul>
            <button
              className={`w-full py-2 rounded ${plan.buttonStyle} transition-colors`}
              onClick={plan.onClick ? plan.onClick : null} // Only Basic is clickable
              disabled={!plan.onClick} // Disable buttons for Professional and Enterprise
            >
              {plan.buttonText}
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Pricing;
