import React, { useRef, useState, useEffect, useContext } from "react";
import { Box, Typography, Button, IconButton, Paper, Popover } from "@mui/material";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import CommonApi from "../../../apis/CommonApi";
import { MyContext } from "../../../Context";

const UpdateResume = ({hideDetails=false}) => {
    const fileInputRef = useRef(null);
      const { setResumeInfo } = useContext(MyContext);
    // State to store resume information
    const [resume, setResume] = useState({
        name: "Fetching resume...", // Placeholder while fetching
        uploadDate: "Please wait", // Placeholder while fetching
        id: null, // Store resume ID for deletion
    });

    // Fetch the primary resume on component mount
    useEffect(() => {
        fetchPrimaryResume();
    }, []);

  
    const fetchPrimaryResume = async () => {
        try {
            const response = await CommonApi.getResumeStatus();
            console.log("API Response:", response); // Debug response

            if (response && response.resumes) {
                // Find the resume with `primary: true`
                const primaryResume = response.resumes.find((resume) => resume.primary === true);
                if (primaryResume) {
                    setResume({
                        name: primaryResume.name,
                        uploadDate: new Date(primaryResume.uploadDate).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        }),
                        id: primaryResume._id, // Store the resume ID for deletion
                    });
                    setResumeInfo(resume)
                } else {
                    setResume({
                        name: "No resume uploaded",
                        uploadDate: "Not available",
                        id: null,
                    });
                    setResumeInfo(resume)
                }
            }
        } catch (error) {
            console.error("Error fetching primary resume:", error);
            setResume({
                name: "No resume uploaded",
                uploadDate: "Not available",
                id: null,
            });
            setResumeInfo(resume)
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const formData = new FormData();
                formData.append("resume", file);

                const response = await CommonApi.uploadIndividualResume(formData);
                console.log("Upload Response:", response); // Debug upload response

                // Fetch and update the primary resume
                fetchPrimaryResume();
            } catch (error) {
                console.error("Error uploading resume:", error);
            }
        }
    };

    const handleUpdateResumeClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDeleteResume = async () => {
        if (!resume.id) {
            console.error("No resume to delete");
            return;
        }

        try {
            const response = await CommonApi.deleteResume(resume.id);
            console.log("Delete Response:", response); // Debug delete response

            // After deletion, clear the resume info and fetch again
            setResume({
                name: "No resume uploaded",
                uploadDate: "Not available",
                id: null,
            });
            fetchPrimaryResume();
        } catch (error) {
            console.error("Error deleting resume:", error);
        }
    };
    

    return (
        <Paper elevation={1} sx={{ boxShadow: "none" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                {!hideDetails &&
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Resume</Typography>
                    <Typography variant="body2" color="textSecondary">{resume.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        Uploaded on {resume.uploadDate}
                    </Typography>
                </Box>
}

            </Box>

            <Box
                sx={{
                    border: "1px dashed #ccc",
                    borderRadius: "8px",
                    padding: "16px",
                    textAlign: "center",
                    width: "100%",
                    mt: 2,
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                        borderRadius: "20px",
                        padding: "6px 16px",
                        fontWeight: "bold",
                        textTransform: "none",
                        marginBottom: "8px",
                    }}
                    onClick={handleUpdateResumeClick}
                >
                    Upload resume
                </Button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept=".doc,.docx,.rtf,.pdf"
                />
                <Typography variant="body2" color="textSecondary">
                    Supported Formats: doc, docx, rtf, pdf, up to 2 MB
                </Typography>
            </Box>
        </Paper>
    );
};

export default UpdateResume;
