import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Container,
  Stack,
  Alert,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IndividualHeader from "../IndividualHeader";
import CommonApi from "../../../apis/CommonApi";
import { Gauge } from "@mui/x-charts/Gauge";
import LocalStorage from "../../../utils/LocalStorage";

const AssessmentDetailPage = () => {
  const location = useLocation();
  const { assessment } = location.state || "";
  const navigate = useNavigate();
  const level1Score = assessment ? assessment.level1Score : null;
  const level2Score = assessment ? assessment.level2Score : null;
  const level1Status = assessment ? assessment.level1Status : null;
  const level2Status = assessment ? assessment.level2Status : null;
  const retryDate = assessment ? assessment.retryDate : null;
  const retries = assessment ? assessment.retries : null;

  useEffect(()=>{
    if(level1Score){
      return;
    }
    navigate('/individual-dashboard')
  },[navigate])

  const [assessmentDetails, setAssessmentDetails] = useState(null); // For report card data
  const [loadingReport, setLoadingReport] = useState(true); // For loading state
  const [errorReport, setErrorReport] = useState(null); // For handling errors
  const [activeStep, setActiveStep] = useState(null);

  useEffect(() => {
    setActiveStep(level1Score >= 35 ? (level2Score ? 2 : 1) : 0);
    return;
  }, []);
  // Redirect back button to individual dashboard
  useEffect(() => {
    const handlePopState = () => {
      navigate("/individual-dashboard");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    // Prevent back navigation when the component is loaded
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  useEffect(()=>{
    let token = LocalStorage.getAccessToken();
    if(!token){
      navigate('/')
    }

  },[])

  useEffect(() => {
    const fetchAssessmentDetails = async () => {
      if (assessment) {
        try {
          const response = await CommonApi.getAssessmentInterviewDetails(
            assessment.assessmentId
          );
          if (response?.message === "Interview not found") {
            setErrorReport("No report available for this assessment.");
            setLoadingReport(false);
          } else {
            setAssessmentDetails(response); // Save report data
            setLoadingReport(false);
          }
        } catch (err) {
          console.error("Error fetching assessment data:", err);
          setErrorReport("Failed to load assessment report.");
          setLoadingReport(false);
        }
      } else {
        setLoadingReport(false);
        setErrorReport("Invalid Assessment Data");
      }
    };

    if (assessment) {
      fetchAssessmentDetails();
    }
  }, [assessment]);

  // Ensure assessment data exists
  if (!assessment) {
    return (
      <Container maxWidth="md" sx={{ mt: 8 }}>
        <Typography color="error" variant="h6">
          Invalid Assessment Data
        </Typography>
      </Container>
    );
  }

  const handleLevel2 = () => {
    navigate("/level-2", { state: { assessmentId: assessment.assessmentId } });
  };

  const handleRetry = async () => {
    let date = new Date().getTime();
    let retryTime = new Date(retryDate).getTime();
    if (date < retryTime) {
      alert(`Please retry after ${retryDate}.`);
    }
    let resp = await CommonApi.retryAssessment(assessment.assessmentId);
    if (resp.success) {
      navigate("/assessment-environment-setup", {
        state: { assessmentId: resp.assessmentId, questions: resp.questions },
      });
    }
  };

  const canRetry = () => {
    const now = new Date();
    const retryTime = new Date(retryDate);
    return retries < 1;
  };

  const steps = [
    { label: "Level 1", status: level1Status, score: level1Score },
    { label: "Level 2", status: level2Status, score: level2Score },
    {
      label: "Final Score",
      status: level2Status,
      score: level2Score + level1Score,
    },
  ];

  // Show loading spinner while fetching the report
  if (loadingReport) {
    return (
      <Container maxWidth="md" sx={{ mt: 8 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <>
      <IndividualHeader />
      <Container maxWidth="md" sx={{ mt: 10 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "12px",
            backgroundColor: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Back Button */}
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <IconButton
              onClick={() => navigate("/individual-dashboard")}
              sx={{ mb: 3 }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>

          <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
            {assessment.name}
          </Typography>

          {/* Alert based on score */}
          {level1Score >= 35 ? (
            <Alert severity="success" sx={{ mb: 3 }}>
              {level2Score?'Assessment Completed. Check your report card.':'Congratulations! You passed Level 1.'}
            </Alert>
          ) : canRetry() ? (
            <Alert severity="error" sx={{ mb: 3 }}>
              You did not pass Level 1. Please retry after 48 hours.
            </Alert>
          ) : (
            <Alert severity="error" sx={{ mb: 3 }}>
              No Further attemps are left.
            </Alert>
          )}



          {/* Stepper */}
          <Box sx={{ mt: 4, mb: 4 }}>
            <Stepper
              // orientation="vertical"
              activeStep={activeStep}
            >
              {steps.map((step, index) => (
                <Step
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <StepLabel>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {step.label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {/* Score Card */}
          {level1Score&&<Box sx={{ paddingTop: 3 }}>
              <Card
                sx={{
                  // maxWidth: 400,
                  margin: "auto",
                  boxShadow: 3,
                  borderRadius: 2,
                  // backgroundColor: "#f5f5f5",
                  padding: 2,
                }}
              >
                <CardContent>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    {/* Level 1 Score */}
                    {level1Score && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6" color="primary">
                          Level 1
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 400 }}>
                          {level1Score}/70
                        </Typography>
                      </Box>
                    )}

                    {/* Level 2 Score */}
                    {level2Score && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6" color="secondary">
                          Level 2
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 400 }}>
                          {level2Score}/30
                        </Typography>
                      </Box>
                    )}

                    {/* Final Score */}
                    {level2Score && (
                      <Box
                        sx={{
                           display: "flex",
                          justifyContent: "space-between",
                          borderTop: "1px solid #ccc",
                          paddingTop: 2,
                        }}
                      >
                        <Typography variant="h6" color="#ED3224">
                          Final Score
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          {level1Score + level2Score}/100
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>

              {/* Action buttons */}
              {level1Score >= 35 ? (
                !level2Score && (
                  <Button
                    variant="text"
                    color="success"
                    sx={{
                      fontWeight: 600,
                      borderRadius: "8px",
                      textTransform: "none",
                      margin: "none",
                      padding: "none",
                      paddingLeft: 0,
                      paddingRight: 0,
                      mt:2
                    }}
                    onClick={handleLevel2}
                  >
                    Attempt Level 2
                  </Button>
                )
              ) : (
                <Button
                  variant="text"
                  color="error"
                  disabled={!canRetry()}
                  sx={{
                    fontWeight: 600,
                    borderRadius: "8px",
                    textTransform: "none",
                    margin: "none",
                    padding: "none",
                    paddingLeft: 0,
                    paddingRight: 0,
                    mt:2,
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#1976D2",
                    },
                  }}
                  onClick={handleRetry}
                >
                  Retry Level 1
                </Button>
              )}
          </Box>}
          
          {/* Show Report Card if available */}
          {assessmentDetails && (
            <Box sx={{ mb: 4,mt:4 }}>
              {/* <Typography variant="h6" sx={{ mb: 2, fontWeight: 600,mt:2 }}>
                Interview analytics:
              </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
                spacing={2}
              >
                {Object.entries(assessmentDetails.scores).map(
                  ([key, value]) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                      key={key}
                    >
                      <Gauge width={100} height={100} value={value} />
                      <Typography variant="h6" sx={{ fontWeight: "400" }}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default AssessmentDetailPage;
