// import React from "react";
// import { Box, Typography, Link, Grid } from "@mui/material";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import SecurityIcon from "@mui/icons-material/Security";
// import ComputerIcon from "@mui/icons-material/Computer";

// const AccountSecurity = () => (
//     <Box>
//         <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
//             Account security
//         </Typography>
//         <Grid container direction="column" spacing={4}>
//             {/* Confirm Email Section */}
//             <Grid item>
//                 <Box display="flex" alignItems="center" gap={1}>
//                     <CheckCircleIcon color="primary" />
//                     <Box>
//                         <Typography variant="body1" sx={{ fontWeight: '500' }}>
//                             Confirm email
//                         </Typography>
//                         <Typography variant="body2" color="textSecondary">
//                             Your email is confirmed
//                         </Typography>
//                         <Link href="#" variant="body2" color="primary">
//                             Change
//                         </Link>
//                     </Box>
//                 </Box>
//             </Grid>

//             {/* 2-Step Verification Section */}
//             <Grid item>
//                 <Box display="flex" alignItems="center" gap={1}>
//                     <SecurityIcon color="action" />
//                     <Box>
//                         <Typography variant="body1" sx={{ fontWeight: '500' }}>
//                             2-step verification
//                         </Typography>
//                         <Typography variant="body2" color="textSecondary">
//                             Protect your account with an extra layer of security.
//                         </Typography>
//                         <Link href="#" variant="body2" color="primary">
//                             Enable
//                         </Link>
//                     </Box>
//                 </Box>
//             </Grid>

//             {/* Recent Login Section */}
//             <Grid item>
//                 <Box display="flex" alignItems="center" gap={1}>
//                     <ComputerIcon color="action" />
//                     <Box>
//                         <Typography variant="body1" sx={{ fontWeight: '500' }}>
//                             Recent login
//                         </Typography>
//                         <Typography variant="body2" color="textSecondary">
//                             Windows, Chrome <br />
//                             Today at 10:33 AM<br />
//                             Hyderabad, India
//                         </Typography>
//                         <Link href="#" variant="body2" color="primary">
//                             See Recent Logins
//                         </Link>
//                     </Box>
//                 </Box>
//             </Grid>
//         </Grid>
//     </Box>
// );

// export default AccountSecurity;
import React, { useEffect, useState } from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SecurityIcon from "@mui/icons-material/Security";
import ComputerIcon from "@mui/icons-material/Computer";
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from "../../../utils/LocalStorage";

const AccountSecurity = () => {
  const [recentLogins, setRecentLogins] = useState([]);
  const [showAllLogins, setShowAllLogins] = useState(false); // Track whether all logins should be shown

  useEffect(() => {
    const fetchRecentLogins = async () => {
      try {
        const token = LocalStorage.getAccessToken();
        const logins = await CommonApi.getRecentLogins(token);
        setRecentLogins(logins);
      } catch (error) {
        console.error("Error fetching recent logins:", error);
      }
    };
    fetchRecentLogins();
  }, []);

  const toggleShowAllLogins = () => {
    setShowAllLogins((prevShowAllLogins) => !prevShowAllLogins);
  };

  return (
    <Box>
      <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
        Account security
      </Typography>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Box display="flex" alignItems="start" gap={1}>
            <CheckCircleIcon color="primary" sx={{mt:0.2}}/>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: '500' }}>
                Confirm email
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Your email is confirmed
              </Typography>
              {/* <Link href="#" variant="body2" color="primary">
                Change
              </Link> */}
            </Box>
          </Box>
        </Grid>
        
        <Grid item>
          <Box display="flex" alignItems="start" gap={1}>
            <SecurityIcon color="action" sx={{mt:0.2}} />
            <Box>
              <Typography variant="body1" sx={{ fontWeight: '500' }}>
                2-step verification
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Protect your account with an extra layer of security.
              </Typography>
              <Link href="#" variant="body2" color="primary">
                Enable
              </Link>
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="start" gap={1}>
            <ComputerIcon color="action" sx={{mt:0.2}} />
            <Box>
              <Typography variant="body1" sx={{ fontWeight: '500' }}>
                Recent login
              </Typography>
              {recentLogins.length > 0 ? (
                <>
                  <Typography variant="body2" color="textSecondary">
                    {recentLogins[0].device}, {recentLogins[0].browser} <br />
                    {new Date(recentLogins[0].timestamp).toLocaleString()}<br />
                    {recentLogins[0].location}
                  </Typography>
                  {showAllLogins &&
                    recentLogins.slice(1).map((login, index) => (
                      <Typography key={index} variant="body2" color="textSecondary">
                        {login.device}, {login.browser} <br />
                        {new Date(login.timestamp).toLocaleString()}<br />
                        {login.location}
                      </Typography>
                    ))
                  }
                  <Link
                    href="#"
                    variant="body2"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleShowAllLogins();
                    }}
                  >
                    {showAllLogins ? "Hide Recent Logins" : "See Recent Logins"}
                  </Link>
                </>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No recent logins available
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountSecurity;

