import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import IndividualHeader from "../IndividualHeader";
import { useLocation, useNavigate } from "react-router-dom";
import CommonApi from "../../../apis/CommonApi";
import useCallbackPrompt from "../../../utils/useCallbackPrompt";

const generateQuestions = (questions) => {
  return questions.map((question) => ({
    id: question.questionId,
    question: question.questionText,
    options: [...question.options],
    answered: false,
    selectedOption: null,
  }));
};

const AssessmentTestPage = () => {
  const [timer, setTimer] = useState(1800); // 30 minutes in seconds
  const [answeredCount, setAnsweredCount] = useState(0);
  const [page, setPage] = useState(1);
  const [showWarning, setShowWarning] = useState(false);
  const [isPageHidden, setIsPageHidden] = useState(false); // Track if the page is hidden
  const [exitMessage, setExitMessage] = useState(""); // State for dynamic exit message
  const location = useLocation();
  const { assessmentId, questions } = location.state || {};

  const [questionsArray, setQuestions] = useState(generateQuestions(questions));
  const totalQuestions = questionsArray.length;
  const questionsPerPage = 5;
  const totalPages = Math.ceil(totalQuestions / questionsPerPage);

  const [level1Score, setLevel1Score] = useState(null); // Store score
  const navigate = useNavigate();

   // Always show the prompt to confirm navigation
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);  // The condition is set to true
    
  const handleWarningClose = () => {
    // setShowWarning(false);
    // setExitMessage(""); // Clear the dynamic exit message
    cancelNavigation()
  };

  const handleExit = async () => {
    // setExitMessage("Exiting the test. Your answers will be saved and your session will be submitted.");
    // setShowWarning(true); // Trigger the dialog when the user tries to exit
    await handleQuit();
    confirmNavigation();
  };

  // Timer countdown
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(
        () => setTimer((prevTimer) => prevTimer - 1),
        1000
      );
      return () => clearInterval(interval);
    } else {
      handleSubmit();
    }
  }, [timer]);

  // Handle Page Visibility: Detect when user switches tabs or minimizes
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       setIsPageHidden(true);
  //     } else {
  //       if (isPageHidden) {
  //         setShowWarning(true); // Show warning if the user switches back to the page
  //       }
  //       setIsPageHidden(false);
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  // }, [isPageHidden]);

  // Handle window focus and blur events (when user switches tabs)
  useEffect(() => {
    const handleFocus = () => {
      if (isPageHidden) {
        setShowWarning(true); // Show warning when user returns to the tab
      }
    };
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [isPageHidden]);

  // Handle browser tab close warning (only the popup)
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      setShowWarning(true); // Show warning dialog if the user tries to close the tab
      return (event.returnValue = "Are you sure you want to leave? The test will be submitted.");
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => window.removeEventListener("beforeunload", handleTabClose);
  }, []);

  const handleSelectOption = (questionId, option) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) => {
        if (q.id === questionId) {
          const wasAnswered = q.answered;
          const updatedQuestion = {
            ...q,
            answered: true,
            selectedOption: option,
          };
          if (!wasAnswered) setAnsweredCount((prev) => prev + 1); // Increment only if first-time answered
          return updatedQuestion;
        }
        return q;
      })
    );
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  // Save progress to the backend
  const saveProgress = async () => {
    try {
      await CommonApi.saveProgress(assessmentId, questionsArray);
    } catch (error) {
      console.error("Error saving progress", error);
    }
  };

  // Handle Submit
  const handleSubmit = async () => {
    try {
      // Save progress before submitting the test
      await saveProgress();

      const response = await CommonApi.submitAssessment(assessmentId, questionsArray);

      const { score, firstName, lastName, assessment } = response;

      if (score !== undefined) {
        setLevel1Score(score);
        // Navigate to the report page after successful submission
        navigate(`/assessment/${assessment.assessmentId}`, { state: { assessment } });
      } else {
        alert("Failed to calculate the score. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the assessment:", error);
      alert("Failed to submit the test. Please try again.");
    }
  };
//handle Quit
const handleQuit = async () => {
  try {
    // Save progress before submitting the test
    await saveProgress();

    const response = await CommonApi.submitAssessment(assessmentId, questionsArray);

    const { score, firstName, lastName, assessment } = response;

    if (score !== undefined) {
      setLevel1Score(score);
      // Navigate to the report page after successful submission
      navigate(`/individual-dashboard`);
    } else {
      alert("Failed to calculate the score. Please try again.");
    }
  } catch (error) {
    console.error("Error submitting the assessment:", error);
    alert("Failed to submit the test. Please try again.");
  }
};
  const displayedQuestions = questionsArray.slice(
    (page - 1) * questionsPerPage,
    page * questionsPerPage
  );

  return (
    <>

      {/* Show the confirmation dialog on navigation attempts */}            
            <Dialog open={showPrompt}>
            <DialogTitle>Confirm Navigation</DialogTitle>
            <DialogContent>
              <p>You are about to navigate away from this page. Are you sure?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleWarningClose} color="primary">
                No
              </Button>
              <Button onClick={handleExit} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {/* <Button onClick={handleWarningClose} color="primary">
            Stay
          </Button>
          <Button onClick={handleExit} color="secondary"> */}
      
      <IndividualHeader />

      <Container maxWidth="xl" sx={{ display: "flex", mt: "100px" }}>
        {/* Sidebar Drawer for Question Navigation */}
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            width: 240,
            flexShrink: 0,
            position: "fixed",
            top: "64px",
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
              backgroundColor: "#f8f9fb",
              borderRight: "1px solid #e0e0e0",
              padding: 2,
              pt: 10,
            },
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", textAlign: "left" }}>
            QUESTION ({questionsArray.length})
          </Typography>
          <List>
            {questionsArray.map((question) => (
              <Tooltip title={question.question} arrow placement="right" key={question.id}>
                <ListItem
                  sx={{
                    mb: 1,
                    p: 1,
                    borderRadius: "8px",
                    backgroundColor: question.answered ? "#e8f5e9" : "#fff",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <ListItemIcon>
                    {question.answered ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : (
                      <RadioButtonUncheckedIcon sx={{ color: "grey" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={`Question ${question.id}`}
                    secondary={question.question.slice(0, 10) + "..."}
                    primaryTypographyProps={{
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                    }}
                    secondaryTypographyProps={{
                      fontSize: "0.75rem",
                      color: "#757575",
                    }}
                  />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Drawer>

        {/* Main Content Area for Questions */}
        <Box
          sx={{
            position: "fixed", // Fix to the top
            top: "64px", // Place it just below the IndividualHeader
            left: "240px", // Align with the right edge of the sidebar
            zIndex: 1000, // Ensure it stays above other content
            backgroundColor: "#1A202C",
            color: "#fff",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "calc(100% - 240px)", // Subtract sidebar width from total width
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            MCQ Test
          </Typography>
          <Typography variant="body2">
            Answered: {answeredCount} / {totalQuestions} | {Math.floor(timer / 60)} mins
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1, ml: "240px", mt: 2 }}>
          <Box sx={{ backgroundColor: "white", borderRadius: "8px", p: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Questions - Page {page} of {totalPages}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {displayedQuestions.map((question) => (
              <Box
                key={question.id}
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {question.question}
                </Typography>
                <RadioGroup
                  value={question.selectedOption || ""}
                  onChange={(e) => handleSelectOption(question.id, e.target.value)}
                  sx={{ mt: 1 }}
                >
                  {question.options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option}
                      control={<Radio />}
                      label={option}
                      sx={{
                        color: question.selectedOption === option ? "#ff2600" : "inherit",
                        "& .MuiRadio-root": {
                          color: question.selectedOption === option ? "#ff2600" : "default",
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              </Box>
            ))}

            {/* Navigation Buttons */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
              {page > 1 && (
                <Button
                  variant="contained"
                  onClick={handlePrevPage}
                  sx={{ backgroundColor: "#ff2600", color: "white" }}
                >
                  Back
                </Button>
              )}
              {page < totalPages ? (
                <Button
                  variant="contained"
                  onClick={handleNextPage}
                  sx={{ backgroundColor: "#ff2600", color: "white" }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#4caf50", color: "white" }}
                  onClick={handleSubmit}
                >
                  Submit Test
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Container>

      {/* Warning Dialog for Exit */}
      {/* <Dialog open={showWarning} onClose={handleWarningClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {exitMessage || "If you exit, your session will be ended, and your answers will be submitted automatically."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningClose} color="primary">
            Stay
          </Button>
          <Button onClick={handleExit} color="secondary">
            Exit
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default AssessmentTestPage;
