// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Typography,
//   Button,
//   Container,
// } from "@mui/material";
// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";
// import "./Level2Page.css";

// const Level2Page = () => {
//   const { transcript, resetTranscript } = useSpeechRecognition();
//   const [setupComplete, setSetupComplete] = useState(true); // Automatically pass setup
//   const [speaking, setSpeaking] = useState(false);
//   const [candidateSpeaking, setCandidateSpeaking] = useState(false);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [recordedChunks, setRecordedChunks] = useState([]);
//   const [isRecording, setIsRecording] = useState(false);

//   useEffect(()=>{
//     startRecording()
//   },[])
//   const startRecording = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: true,
//         audio: true,
//       });
//       const recorder = new MediaRecorder(stream);
//       setMediaRecorder(recorder);

//       recorder.ondataavailable = (event) => {
//         if (event.data.size > 0) {
//           setRecordedChunks((prev) => [...prev, event.data]);
//         }
//       };

//       recorder.start();
//       setIsRecording(true);
//       const videoElement = document.getElementById("candidate-video");
//       videoElement.srcObject = stream;
//       videoElement.muted = true;
//       videoElement.play();
//     } catch (error) {
//       console.error("Error starting recording: ", error);
//     }
//   };

//   const stopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//       setIsRecording(false);

//       const mimeType = MediaRecorder.isTypeSupported("video/webm")
//         ? "video/webm"
//         : "video/mp4";
//       const blob = new Blob(recordedChunks, { type: mimeType });
//       const url = URL.createObjectURL(blob);
//       const downloadLink = document.createElement("a");
//       downloadLink.href = url;
//       downloadLink.download = `interview.${mimeType.split("/")[1]}`;
//       downloadLink.click();
//     }
//   };

//   const speakText = (text) => {
//     const synth = window.speechSynthesis;
//     const utterance = new SpeechSynthesisUtterance(text);
//     utterance.onstart = () => setSpeaking(true);
//     utterance.onend = () => {
//       setSpeaking(false);
//       SpeechRecognition.startListening({ continuous: true });
//     };
//     synth.speak(utterance);
//   };

//   const handleCandidateResponse = () => {
//     if (transcript.trim()) {
//       speakText(`You said: ${transcript}`);
//       resetTranscript();
//     }
//   };

//   return (
//     <Container>
//       <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
//         {/* <Typography variant="h5" gutterBottom>
//           AI Video Interview
//         </Typography> */}
//         <video
//           id="candidate-video"
//           style={{ width: "300px", height: "200px", marginBottom: "20px" }}
//           autoPlay
//           muted
//         />
//         <Box
//           className={`dot ${speaking ? "speaking" : ""}`}
//           sx={{ width: 100, height: 100, borderRadius: "50%", bgcolor: "black" }}
//         />
//         <Typography>
//           {speaking ? "Chatbot is speaking..." : "Waiting for your response..."}
//         </Typography>
//         {/* <Button
//           variant="contained"
//           onClick={startRecording}
//           disabled={isRecording}
//           sx={{ mt: 4 }}
//         >
//           {isRecording ? "Recording..." : "Start Recording"}
//         </Button>
//         <Button
//           variant="contained"
//           onClick={stopRecording}
//           disabled={!isRecording}
//           sx={{ mt: 2 }}
//         >
//           Stop Recording
//         </Button>
//         <Typography mt={4}>Your Response: {transcript}</Typography>
//         <Button
//           variant="contained"
//           onClick={handleCandidateResponse}
//           sx={{ mt: 2 }}
//         >
//           Submit Response
//         </Button> */}
//       </Box>
//     </Container>
//   );
// };

// export default Level2Page;

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import socket from "../../../utils/socket";
import "./Level2Page.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import IndividualHeader from "../IndividualHeader";
import useCallbackPrompt from "../../../utils/useCallbackPrompt";

const Level2Page = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcripts, setTranscripts] = useState([]);
  const recognitionRef = useRef(null); // Ref to store recognition instance
  const audioRef = useRef(null); // Ref to store the audio element
  const location = useLocation()
  const navigate= useNavigate()
  const { assessmentId }= location.state || ""

      // Always show the prompt to confirm navigation
      const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);  // The condition is set to true

      const handleWarningClose = () => {
        // setShowWarning(false);
        // setExitMessage(""); // Clear the dynamic exit message
        cancelNavigation()
      };
    
      const handleExit = async () => {
        // setExitMessage("Exiting the test. Your answers will be saved and your session will be submitted.");
        // setShowWarning(true); // Trigger the dialog when the user tries to exit
        // await handleQuit();
        socket.emit('chat-end',transcripts)
        confirmNavigation();
      };

  useEffect(() => {
    if(!assessmentId){
      navigate('/individual-dashboard')
    }
    socket.connect();
    socket.emit('initialize',  assessmentId );     

    // Listen for responses containing both transcript and audio
    const handleResponse = ({ transcript, audioBuffer }) => {
      console.log(transcripts)
      if (transcript) {
        setTranscripts((prev) => [
          ...prev,
          { sender: "HR", message: transcript },
        ]);
        // console.log(transcripts)
        // const regex = new RegExp(`\\b${"Thank you, Best of luck"}\\b`, 'i');
        // if (regex.test(transcript)) {
        //   socket.emit('chat-end',transcripts)
        //   // console.log(transcripts)
        //   // socket.disconnect()
        //   // setTranscripts([])
        // }
      }


      if (audioBuffer) {
        const audioBlob = new Blob([audioBuffer], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        audioRef.current = audio; // Store audio element reference

        // Pause recognition when audio starts
        audio.onplay = () => {
          if (recognitionRef.current) {
            recognitionRef.current.stop(); // Stop recognition when audio plays
          }
        };

        // Resume recognition when audio ends
        audio.onended = () => {
          if (recognitionRef.current) {
            recognitionRef.current.start(); // Start recognition when audio ends
          }
        };

        // Play the audio
        audio.playbackRate = 1.2; 
        audio.play();
      }
    };



    socket.on("response", handleResponse);

    return () => {
      socket.off("response", handleResponse);
      socket.disconnect();
    };
  }, []);

  const startRecording = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Speech recognition is not supported in this browser.");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-IN"; // Set Indian English

    recognitionRef.current = recognition; // Store recognition instance

    recognition.onstart = () => setIsRecording(true);
    recognition.onend = () => setIsRecording(false);

    recognition.onresult = (event) => {
      let finalTranscript = "";

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript;
        }
      }

      if (finalTranscript) {
        setTranscripts((prev) => [
          ...prev,
          { sender: "You", message: finalTranscript },
        ]);

        // Stop recognition before sending the text to the server
        recognition.stop();

        socket.emit("text-chunk", finalTranscript); // Send text chunk to server
      }
    };

    recognition.start();
  };

  const stopRecording = () => {
    setIsRecording(false);
    if (recognitionRef.current) {
      recognitionRef.current.stop(); // Stop recognition
    }
  };

  useEffect(()=>{
    const regex = new RegExp(`\\b${"Thank you, Best of luck"}\\b`, 'i');
    let transcript=transcripts[transcripts.length-1]?.message
    console.log(transcripts)
    if (regex.test(transcript)) {
      socket.emit('chat-end',transcripts)
      // console.log(transcripts)
      navigate('/individual-dashboard')
      // socket.disconnect()
      // setTranscripts([])
    }
  },[transcripts])

  return (
    <>
          {/* Show the confirmation dialog on navigation attempts */}            
          <Dialog open={showPrompt}>
            <DialogTitle>Confirm Navigation</DialogTitle>
            <DialogContent>
              <p>You are about to navigate away from this page. Are you sure?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleWarningClose} color="primary">
                No
              </Button>
              <Button onClick={handleExit} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>

    <IndividualHeader />
    <Container className="gradient-bg" maxWidth="sm" sx={{ textAlign: "center",height:"100vh",width:"100vw",maxWidth:"100% !important",display:"flex",alignItems:"center",justifyContent:"center" }}>
            
            {/* <Typography variant="h6" sx={{ fontWeight: "regular",position:"absolute",top:"80px",left:30,color:"#ED3829" }}>
              Santosh TechOptima
            </Typography> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              mb: 3,
            }}
          >
            <div class="circles" style={{position:"absolute"}}>
              <div class="circle1"></div>
              <div class="circle2"></div>
              <div class="circle3"></div>
            </div>
            <IconButton
              onClick={isRecording ? stopRecording : startRecording}
              color="primary"
              size="large"
              className={isRecording ? "mic-button active" : "mic-button"}
              sx={{
                width: 80,
                height: 80,
                bgcolor: isRecording ? "secondary.main" : "#ED3829",
                color: "white",
                "&:hover": {
                  bgcolor: isRecording ? "secondary.dark" : "#F05246",
                },
              }}
            >
              {isRecording ? <MicIcon fontSize="large" /> : <MicOffIcon fontSize="large" />}
            </IconButton>
          </Box>
          {/* <Box sx={{ mt: 2, textAlign: "left" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {transcripts.map((text, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    borderRadius: 2,
                    bgcolor: text.sender === "You" ? "primary.light" : "secondary.light",
                    color: text.sender === "You" ? "primary.contrastText" : "secondary.contrastText",
                    alignSelf: text.sender === "You" ? "flex-start" : "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      mb: 0.5,
                      color: text.sender === "You" ? "primary.dark" : "secondary.dark",
                    }}
                  >
                    {text.sender}
                  </Typography>
                  <Typography>{text.message}</Typography>
                </Box>
              ))}
            </Box>
          </Box> */}
    </Container>
    </>
  );
};

export default Level2Page;