// // import React,{useState} from 'react';
// // import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
// // import Sidebar from '../layouts/sidebar/Sidebar';
// // import Navbar from '../layouts/navbar/Navbar';
// // import { Box } from '@mui/material';
// // import LocalStorage from '../utils/LocalStorage';
// // import NotAllowedPage from '../layouts/NotAllowedPage';
// // import SuperAdminRoutes from './AdminRoute'; // platformOwner-specific routes
// // import CompanyAdminRoutes from './CompanyAdminRoutes'; // Admin-specific routes
// // import CandidateRoutes from './CandidateRoute'; // Candidate-specific routes
// // import { makeStyles } from '@mui/styles';
// // import { useTheme } from "@mui/system";

// // var drawerWidth = 260;
// // const useStyles = makeStyles(() => ({
// //   box: {
// //     // display: 'flex',
// //   },
// //   // mainBox: {
// //   //   flexGrow: 1,
// //   //   width: { sm: `calc(100% - ${drawerWidth}px)` },
// //   //   backgroundColor: 'white',
// //   // },
// //   mainBox: {
// //     flexGrow: 1,
// //     width: { sm: `calc(100% - ${drawerWidth}px)` },
// //     backgroundColor: 'white',
// //     paddingLeft: "80px",
// //     [useTheme().breakpoints.down("sm")]: {
// //       padding: "0px",
// //     },
// //   },
// //   mainBoxOpen: {
// //     paddingLeft: "275px",
// //   },
// //   menuButton: {
// //     marginRight: 2,
// //     display: { sm: "auto" },

// //    width: "10px",
// //    paddingRight: "55px",
// //   },
// //   drawerPaper: {
// //     width: "80px",
// //   },
// //   drawerContainer: {
// //     width: "80px",
// //   },
// // }));

// // export default function PrivateRoutes() {
// //   const classes = useStyles();
// //   const navigate = useNavigate();
// //   const location = useLocation();
// //   const [sidebarOpen, setSidebarOpen] = useState(false);

// //   const user = LocalStorage.getUserData();
// //   const isPlatformOwner = user && user.role === 'platformOwner';
// //   const isCandidate = user && user.role === 'candidate'; // Check if the user is a candidate

// //   let routes = CompanyAdminRoutes;
// //   if (isPlatformOwner) {
// //     routes = SuperAdminRoutes;
// //   } else if (isCandidate) {
// //     routes = CandidateRoutes;
// //   }

// //   return (
// //     <div>
// //       {LocalStorage.getAccessToken() ? (
// //         <Box className={classes.box}>
// //           {location.pathname !== '/404' && <Navbar />}
// //           <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
// //           <Box component="main" className={`${classes.mainBox} ${sidebarOpen ? classes.mainBoxOpen : ''}`}>
// //             <Routes>
// //               {routes.map((route, key) => (
// //                 <Route key={key} path={route.path} element={route.element} />
// //               ))}
// //               <Route path="/404" element={<NotAllowedPage />} />
// //               <Route path="*" element={<Navigate to="/" replace />} />
// //             </Routes>
// //           </Box>
// //         </Box>
// //       ) : (
// //         navigate("/")
// //       )}
// //     </div>
// //   );
// // }


// // import React, { useState, useEffect } from 'react';
// // import {
// //   Routes, Route, Navigate, useNavigate, useLocation,
// // } from 'react-router-dom';
// // import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
// // import Sidebar from '../layouts/sidebar/Sidebar';
// // import Navbar from '../layouts/navbar/Navbar';
// // import LocalStorage from '../utils/LocalStorage';
// // import NotAllowedPage from '../layouts/NotAllowedPage';
// // import SuperAdminRoutes from './AdminRoute';
// // import CompanyAdminRoutes from './CompanyAdminRoutes';
// // import CandidateRoutes from './CandidateRoute';
// // import EmployerRoutes from './EmployerRoutes';
// // import IntervieweeRoutes from './IntervieweeRoutes';
// // import { makeStyles } from '@mui/styles';
// // import { useTheme } from "@mui/system";

// // const drawerWidth = 260;
// // const useStyles = makeStyles(() => ({
// //   box: {},
// //   mainBox: {
// //     flexGrow: 1,
// //     width: { sm: `calc(100% - ${drawerWidth}px)` },
// //     backgroundColor: 'white',
// //     paddingLeft: "80px",
// //     [useTheme().breakpoints.down("sm")]: {
// //       padding: "0px",
// //     },
// //   },
// //   mainBoxOpen: {
// //     paddingLeft: "280px",
// //   },
// //   menuButton: {
// //     marginRight: 2,
// //     display: { sm: "auto" },
// //     width: "10px",
// //     paddingRight: "55px",
// //   },
// //   drawerPaper: {
// //     width: "80px",
// //   },
// //   drawerContainer: {
// //     width: "80px",
// //   },
// // }));

// // export default function PrivateRoutes() {
// //   const classes = useStyles();
// //   const navigate = useNavigate();
// //   const location = useLocation();
// //   const [sidebarOpen, setSidebarOpen] = useState(false);
// //   const [greetingMessage, setGreetingMessage] = useState('');
// //   const [openPopup, setOpenPopup] = useState(true); // State to control the popup visibility

// //   const user = LocalStorage.getUserData();
// //   const userName = user ? user.name : 'User';
// //   const isPlatformOwner = user && user.role === 'platformOwner';
// //   const isCandidate = user && user.role === 'candidate';
// //   const isEmployer = user && user.role === 'employer';
// //   const isInterviewee = user && user.role === 'interviewee';

// //   let routes = CompanyAdminRoutes;
// //   if (isPlatformOwner) {
// //     routes = SuperAdminRoutes;
// //   } else if (isCandidate) {
// //     routes = CandidateRoutes;
// //   } else if (isEmployer) {
// //     routes = EmployerRoutes;
// //   }

// //   routes = routes || [];

// //   // Redirect to the landing page if no token is found
// //   if (!LocalStorage.getAccessToken()) {
// //     return <Navigate to="/login" />;
// //   }

// //   // Function to determine the greeting message based on the current time
// //   const getGreetingMessage = () => {
// //     const currentHour = new Date().getHours();
// //     if (currentHour < 12) {
// //       return 'Good morning';
// //     } else if (currentHour < 18) {
// //       return 'Good afternoon';
// //     } else {
// //       return 'Good evening';
// //     }
// //   };

// //   useEffect(() => {
// //     setGreetingMessage(getGreetingMessage());
// //   }, []);

// //   const handlePopupClose = () => {
// //     setOpenPopup(false);
// //   };

// //   const handleCreateJobClick = () => {
// //     setOpenPopup(false);
// //     navigate('/jobdescription');
// //   };

// //   return (
// //     <div>
// //       <Box className={classes.box}>
// //         {location.pathname !== '/404' && <Navbar />}
// //         <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
// //         <Box component="main" className={`${classes.mainBox} ${sidebarOpen ? classes.mainBoxOpen : ''}`}>
// //           <Routes>
// //             {routes.map((route, key) => (
// //               <Route key={key} path={route.path} element={route.element} />
// //             ))}
// //             <Route path="/404" element={<NotAllowedPage />} />
// //             <Route path="*" element={<Navigate to="/dashboard" replace />} />
// //           </Routes>
// //         </Box>
// //       </Box>

// //       {/* Popup Dialog for Greeting Message */}
// //       <Dialog open={openPopup} onClose={handlePopupClose}>
// //         <DialogTitle>
// //           {greetingMessage}, {userName} 👋
// //         </DialogTitle>
// //         <DialogContent>
// //           <Typography>
// //             Do you want to create a new job description? Click below.
// //           </Typography>
// //         </DialogContent>
// //         <DialogActions>
// //           <Button onClick={handleCreateJobClick} variant="contained" color="primary">
// //             Create Job Description
// //           </Button>
// //           <Button onClick={handlePopupClose} variant="outlined" color="secondary">
// //             Close
// //           </Button>
// //         </DialogActions>
// //       </Dialog>
// //     </div>
// //   );
// // }
// import React, { useState, useEffect } from 'react';
// import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
// import Sidebar from '../layouts/sidebar/Sidebar';
// import Navbar from '../layouts/navbar/Navbar';
// import IndividualHeader from '../views/individual/IndividualHeader';
// import { Box } from '@mui/material';
// import LocalStorage from '../utils/LocalStorage';
// import NotAllowedPage from '../layouts/NotAllowedPage';
// import SuperAdminRoutes from './AdminRoute';
// import CompanyAdminRoutes from './CompanyAdminRoutes';
// import CandidateRoutes from './CandidateRoute';
// import EmployerRoutes from './EmployerRoutes';
// import IntervieweeRoutes from './IntervieweeRoutes';
// import { makeStyles } from '@mui/styles';
// import { useTheme } from "@mui/system";
// import SearchJob from '../views/individual/SearchJob';
// import SearchResults from '../views/individual/SearchResults';
// import JobDescriptionDetails from '../views/individual/JobDescriptionDetails';
// import IndividualFooter from '../views/individual/IndividualFooter';
// import AccountSettings from '../views/individual/settings/AccountSettings';
// import EmployerHomePage from '../views/employer/EmployerHomePage';

// const useStyles = makeStyles(() => ({
//   box: {},
//   mainBox: {
//     flexGrow: 1,
//     width: "100%",
//     backgroundColor: 'white',
//     [useTheme().breakpoints.down("sm")]: {
//       padding: "0px",
//     },
//   },
//   mainBoxOpen: {},
//   menuButton: {
//     marginRight: 2,
//     display: { sm: "auto" },
//     width: "10px",
//     paddingRight: "55px",
//   },
//   drawerPaper: {
//     width: "80px",
//   },
//   drawerContainer: {
//     width: "80px",
//   },
// }));

// export default function PrivateRoutes() {
//   const classes = useStyles();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [sidebarOpen, setSidebarOpen] = useState(false);

//   const user = LocalStorage.getUserData();
//   const hasToken = Boolean(LocalStorage.getAccessToken());

//   const isPlatformOwner = user && user.role === 'platformOwner';
//   const isCandidate = user && user.role === 'candidate';
//   const isEmployer = user && user.role === 'employer';
//   const isInterviewee = user && user.role === 'interviewee';

//   let routes = CompanyAdminRoutes;

//   if (isPlatformOwner) {
//     routes = SuperAdminRoutes;
//   } else if (isCandidate) {
//     routes = CandidateRoutes;
//   } else if (isEmployer) {
//     routes = EmployerRoutes;
//   } 

//   routes = routes || [];

//   const individualPublicPaths = ['/search-job', '/search-results', '/job-description', '/employer-homepage'];
//   const employerPublicPaths = ['/employer-homepage', '/employer-search', '/employer-settings'];
//   const showIndividualHeaderAndFooter = individualPublicPaths.includes(location.pathname);
//   const showEmployerHeaderAndFooter = isEmployer && employerPublicPaths.includes(location.pathname);

//   useEffect(() => {
//     if (!hasToken) {
//       navigate('/login');
//     }
//   }, [navigate, hasToken]);

//   return (
//     <div>
//       <Box className={classes.box}>
//         {hasToken ? (
//           <>
//             {location.pathname !== '/404' && <Navbar />}
//             <Box component="main" className={`${classes.mainBox} ${sidebarOpen ? classes.mainBoxOpen : ''}`}>
//               <Routes>
//                 {routes.map((route, key) => (
                  
//                   <Route key={key} path={route.path} element={route.element} />
//                 ))}
//                 <Route path="/404" element={<NotAllowedPage />} />
//                 <Route path="*" element={<Navigate to="/dashboard" replace />} />
//               </Routes>
//             </Box>
//           </>
//         ) : (
//           <>
//             {showIndividualHeaderAndFooter && <IndividualHeader />}
//             {/* {showEmployerHeaderAndFooter && <EmployerHeader />} */}
//             <Box component="main" className={classes.mainBox}>
//               <Routes>
//                 <Route path="/search" element={<SearchJob />} />
//                 <Route path="/results" element={<SearchResults />} />
//                 <Route path="/job-description" element={<JobDescriptionDetails />} />
//                 <Route path="/account-settings" element={<AccountSettings />} />
//                 {/* Add Employer-specific routes */}
//                 <Route path="/employer-homepage" element={<EmployerHomePage />} />
//                 {/* <Route path="/employer-search" element={<EmployerSearch />} />
//                 <Route path="/employer-settings" element={<EmployerSettings />} /> */}
//               </Routes>
//             </Box>
//             {showIndividualHeaderAndFooter && <IndividualFooter />}
//             {/* {showEmployerHeaderAndFooter && <EmployerFooter />} */}
//           </>
//         )}
//       </Box>
//     </div>
//   );
// }

import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../layouts/navbar/Navbar';
import IndividualHeader from '../views/individual/IndividualHeader';
import { Box, CircularProgress } from '@mui/material';
import LocalStorage from '../utils/LocalStorage';
import NotAllowedPage from '../layouts/NotAllowedPage';
import SuperAdminRoutes from './AdminRoute';
import CompanyAdminRoutes from './CompanyAdminRoutes';
import CandidateRoutes from './CandidateRoute';
import EmployerRoutes from './EmployerRoutes';
import { makeStyles } from '@mui/styles';
import { useTheme } from "@mui/system";
import SearchJob from '../views/individual/SearchJob';
import SearchResults from '../views/individual/SearchResults';
import JobDescriptionDetails from '../views/individual/JobDescriptionDetails';
import IndividualFooter from '../views/individual/IndividualFooter';
import AccountSettings from '../views/individual/settings/AccountSettings';
import EmployerHomePage from '../views/employer/EmployerHomePage';
import EmailVerificationSuccess from '../views/individual/settings/EmailVerificationSuccess';
import VerifyEmail from '../views/individual/settings/VerifyEmail';
import IndividualRoutes from './IndividualRoute';
import CommonApi from '../apis/CommonApi';

const useStyles = makeStyles(() => ({
  box: {},
  mainBox: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: 'white',
    [useTheme().breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  mainBoxOpen: {},
  menuButton: {
    marginRight: 2,
    display: { sm: "auto" },
    width: "10px",
    paddingRight: "55px",
  },
  drawerPaper: {
    width: "80px",
  },
  drawerContainer: {
    width: "80px",
  },
}));

export default function PrivateRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const user = LocalStorage.getUserData();
  const hasToken = Boolean(LocalStorage.getAccessToken());

  const isPlatformOwner = user && user.role === 'platformOwner';
  const isCandidate = user && user.role === 'candidate';
  const isEmployer = user && user.role === 'employer';
  const isIndividual = user && user.role === 'individual';

  let routes = CompanyAdminRoutes;

  if (isPlatformOwner) {
    routes = SuperAdminRoutes;
  } else if (isCandidate) {
    routes = CandidateRoutes;
  } else if (isEmployer) {
    routes = EmployerRoutes;
  } else if (isIndividual) {
    routes = IndividualRoutes;
  }

  const individualPublicPaths = ['/search', '/search-results', '/job-description'];
  const showIndividualHeaderAndFooter = individualPublicPaths.includes(location.pathname);

  // Check profile completion and redirect appropriately
  useEffect(() => {
    const redirectBasedOnProfile = async () => {
      if (!hasToken || !isIndividual) {
        setIsLoading(false);
        return;
      }
  
      try {
        const response = await CommonApi.getProfileCompletion();
        const profileCompletion = response.profileCompletion;
  
        console.log('Profile Completion Fetched:', profileCompletion);
  
        // Explicitly check the profile completion
        if (profileCompletion >= 75) {
          console.log('Redirecting to /assessment-main');
          if (location.pathname === '/' || location.pathname === '/login') {
            navigate('/assessment-main', { replace: true });
          }
        } else {
          console.log('Redirecting to /account-settings');
          if (location.pathname === '/' || location.pathname === '/login') {
            navigate('/account-settings', { replace: true });
          }
        }
      } catch (error) {
        console.error('Error checking profile completion:', error);
        if (location.pathname === '/' || location.pathname === '/login') {
          navigate('/account-settings', { replace: true });
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    redirectBasedOnProfile();
  }, [hasToken, isIndividual, navigate, location.pathname]);
    
  

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Box>
        {hasToken ? (
          <>
            {!isIndividual && location.pathname !== '/404' && <Navbar />}
            <Box component="main" className={`${sidebarOpen ? 'mainBoxOpen' : ''}`}>
              <Routes>
                {isIndividual ? (
                  <>
                    {routes.map((route, key) => (
                      <Route key={key} path={route.path} element={route.element} />
                    ))}
                  </>
                ) : (
                  <>
                    {routes.map((route, key) => (
                      <Route key={key} path={route.path} element={route.element} />
                    ))}
                    <Route path="/verify-email" element={<VerifyEmail />} />
                    <Route path="/404" element={<NotAllowedPage />} />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                  </>
                )}
              </Routes>
            </Box>
          </>
        ) : (
          <>
            {showIndividualHeaderAndFooter && <IndividualHeader />}
            <Box component="main">
              <Routes>
                <Route path="/search" element={<SearchJob />} />
                <Route path="/results" element={<SearchResults />} />
                <Route path="/job-description" element={<JobDescriptionDetails />} />
                <Route path="/account-settings" element={<AccountSettings />} />
                <Route path="/employer-homepage" element={<EmployerHomePage />} />
                <Route path="/verify-email-success" element={<EmailVerificationSuccess />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
              </Routes>
            </Box>
            {showIndividualHeaderAndFooter && <IndividualFooter />}
          </>
        )}
      </Box>
    </div>
  );
}
