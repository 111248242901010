import React from 'react';
import Dashboard from '../views/admin/Dashboard';
import CompanyDashboard from '../views/admin/employees/CompanyDashboard';
import InviteEmployee from '../views/admin/employees/InviteEmployee';
import Login from '../layouts/login/Login';
import ControlledAccordions from '../views/admin/employees/userprofile/UserProfile';
import ForgotPassword from '../layouts/forgotPassword/ForgotPassword';
import MyProfile from '../layouts/MyProfile';
import CompanyUsers from '../views/admin/employees/CompanyUsers';
import JobDetails from '../views/admin/employees/jobDetails/JobDetails';
import Jobs from '../views/companyAdmin/JobDescription/Jobs';
import ThemedDashboard from '../views/admin/dashboardComponents/ThemedDashboard';
import JobDescriptions from "../views/admin/employees/jobDescription/JobDescription";
import JobDescriptionStepper from '../views/companyAdmin/JobDescription/JobDescriptionStepper';
import TokensUsageDashboard from '../views/companyAdmin/TokensUsageDashboard';

const SuperAdminRoutes = [
  {
    id: "admin",
    path: "/*",
    element: <Login />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/",
    element: <Login />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/dashboard",
    element: <ThemedDashboard />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/company",
    element: <CompanyDashboard />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/companies/:companyId/users",
    element: <CompanyUsers />,
    name: "",
    slug: "dashboard",
    access: true
  },


  {
    id: "admin",
    path: "/employees/user-profile",
    element: <ControlledAccordions />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/employees/add",
    element: <InviteEmployee />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/forgot-password",
    element: <ForgotPassword />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/my-profile",
    element: <MyProfile />,
    name: "",
    slug: "dashboard",
    access: true
  },
  // {
  //   id: "admin",
  //   path: "/job_details",
  //   element: <JobDetails />,
  //   name: "",
  //   slug: "dashboard",
  //   access: true
  // },
  {
    id: "admin",
    path: "/jobs",
    element: <Jobs />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    path: "/jobdescription",
    element: <JobDescriptions/>,  // Add the new route
    name: "Chatbot",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/jobdescriptionstepper",  // Add the new route here
    element: <JobDescriptionStepper />, // Component for JobDescriptionStepper
    name: "Job Description Stepper",
    slug: "dashboard",
    access: true
  },
  {
    id: "tokensUsage",
    path: "/tokens-usage",
    element: <TokensUsageDashboard />,
    name: "Tokens Usage",
    slug: "tokens-usage",
    access: true
  },
]

export default SuperAdminRoutes;