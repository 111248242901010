import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import CommonApi from "../../../apis/CommonApi"; // Import your CommonApi
import LocalStorage from "../../../utils/LocalStorage";

const Dashboard = () => {
  const [assessments, setAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopup, setShowPopup] = useState(false); // Control popup visibility
  const [popupMessage, setPopupMessage] = useState(""); // Message for the popup
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false); // Show additional info on popup close
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        console.log("Fetching assessments...");
        const response = await CommonApi.getAssessmentList();
        console.log("Response received:", response);

        if (response.success && response.assessments.length > 0) {
          setAssessments(response.assessments);
          setFilteredAssessments(response.assessments);
        } else {
          setPopupMessage("No assessments found. Start by creating or exploring assessments.");
          setShowPopup(true);
        }
      } catch (error) {
        console.error("Error fetching assessments:", error);
        setPopupMessage("No assessment found. Please try again later.");
        setShowPopup(true);
      }
    };

    fetchAssessments();
  }, []);

  useEffect(()=>{
    let token = LocalStorage.getAccessToken();
    if(!token){
      navigate('/')
    }

  },[])

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = assessments.filter((assessment) =>
      assessment.name.toLowerCase().includes(query)
    );
    setFilteredAssessments(filtered);
  };

  const handleRefresh = () => {
    setSearchQuery("");
    setFilteredAssessments(assessments);
  };

  const handleNavigateToAssessments = () => {
    setShowPopup(false);
    navigate("/assessment-main"); // Navigate to assessments page
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowAdditionalInfo(true); // Show additional info section
  };

  const handleAction = (assessment) => {
    // switch (assessment.status) {
    //   case "completed":
    //     navigate(`/report/${assessment.assessmentId}`);
    //     break;
    //   case "Retry Level 1":
    //     const today = new Date();
    //     const retryDate = new Date(assessment.retryDate);
    //     if (today >= retryDate) {
    //       navigate("/level-1", { state: { assessmentId: assessment.assessmentId } });
    //     } else {
    //       setPopupMessage(`Retry available after ${retryDate.toLocaleDateString()}`);
    //       setShowPopup(true);
    //     }
    //     break;
    //   case "Level 2 Available":
    //     navigate("/level-2", { state: { assessmentId: assessment.assessmentId } });
    //     break;
    //   default:
    //     navigate(`/assessment/${assessment.assessmentId}`, { state: { assessment } });
    //     break;
    // }
    navigate(`/assessment/${assessment.assessmentId}`, { state: { assessment } });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 9, pb: 6 }}>
      <Typography variant="h4" gutterBottom textAlign="center">
        My Assessments
      </Typography>

      {/* Search and Refresh */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <TextField
          placeholder="Search assessments..."
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: "60%", mr: 2 }}
        />
        <Tooltip title="Refresh List">
          <IconButton onClick={handleRefresh} color="primary">
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {filteredAssessments.length === 0 && !showAdditionalInfo ? (
        <Typography variant="body1" color="text.secondary" textAlign="center">
          Loading assessments...
        </Typography>
      ) : showAdditionalInfo ? (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            No Assessments Found
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            It seems you haven't started or completed any assessment yet. Begin your journey by exploring available assessments.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigateToAssessments}
          >
            Start an Assessment
          </Button>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {filteredAssessments.map((assessment) => (
            <Grid item xs={12} md={6} key={assessment.assessmentId}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  borderRadius: "12px",
                  backgroundColor: "#ffffff",
                  textAlign: "center",
                  position: "relative",
                  border: "1px solid #e0e0e0",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                {/* Title Section */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    color: "#333",
                    textTransform: "uppercase",
                  }}
                  gutterBottom
                >
                  {assessment.name}
                </Typography>

                {/* Status Section */}
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.95rem",
                    fontWeight: 500,
                    color: assessment.status === "Completed" ? "#28a745" : "#ff2600",
                    mb: 1,
                  }}
                >
                  Status: {assessment.status}
                </Typography>

                {/* Score Section */}
                {assessment.level1Score !== null && (
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.9rem",
                      color: "#6c757d",
                      mb: 0.5,
                    }}
                  >
                    Level 1 Score: <strong>{assessment.level1Score}/70</strong>
                  </Typography>
                )}
                {assessment.level2Score !== null && (
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.9rem",
                      color: "#6c757d",
                      mb: 1.5,
                    }}
                  >
                    Level 2 Score: <strong>{assessment.level2Score}/30</strong>
                  </Typography>
                )}

                {/* Action Button */}
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    px: 3,
                    py: 1,
                    backgroundColor: "#ff2600",
                    color: "#fff",
                    fontWeight: 600,
                    borderRadius: "20px",
                    "&:hover": {
                      backgroundColor: "#e62000",
                    },
                  }}
                  onClick={() => handleAction(assessment)}
                >
                  {assessment.status === "Completed" ? "View Report" : "View"}
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Popup for messages */}
      <Dialog open={showPopup} onClose={handleClosePopup}>
        <DialogTitle>Notice</DialogTitle>
        <DialogContent>
          <Typography>{popupMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigateToAssessments}
          >
            Go to Assessments
          </Button>
          <Button
            variant="outlined"
            onClick={handleClosePopup}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Dashboard;
