import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Tooltip,
  useMediaQuery,
  Button,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AgCharts } from "ag-charts-react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarRateIcon from "@mui/icons-material/StarRate";
import InfoIcon from "@mui/icons-material/Info";
import IndividualHeader from "../IndividualHeader";
import Dashboard from "../assessment/AssessmentDashboard";
import MainReport from './MainReport.js'

const theme = createTheme({
  palette: {
    neutral: { main: "#2e3b4e" },
    background: { default: "#f4f6f8", paper: "#ffffff" },
    accent: { main: "#ff4c4c" },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h4: { fontWeight: "600", color: "#2e3b4e" },
    h6: { color: "#607d8b", fontWeight: "bold" },
    body2: { color: "#5f6368" },
  },
});

const cardBackgroundColors = ["#fdecea", "#e0f2f1", "#e8eaf6", "#fff3e0"];
const assessmentData = [
  { title: "Communication", score: 80, avgScore: 22 },
  { title: "Behavior", score: 60, avgScore: 17 },
  { title: "Skills", score: 100, avgScore: 24 },
  { title: "Professionalism", score: 70, avgScore: 20 },
];

const questionAnswerData = [
  {
    question: "Q1: How do you handle conflicts?",
    answer: "By open communication.",
    score: 80,
    time: "1m 30s",
  },
  {
    question: "Q2: What motivates you?",
    answer: "Achieving set goals.",
    score: 85,
    time: "2m 15s",
  },
  {
    question: "Q3: Describe a successful project.",
    answer: "Managed project X successfully.",
    score: 90,
    time: "2m 0s",
  },
];

const AssessmentDashboard = () => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  const barChartOptions = {
    title: { text: "Question-wise Comparison" },
    data: [
      { question: "Q1", individualScore: 70, avgScore: 65 },
      { question: "Q2", individualScore: 80, avgScore: 75 },
      { question: "Q3", individualScore: 60, avgScore: 70 },
      { question: "Q4", individualScore: 85, avgScore: 80 },
      { question: "Q5", individualScore: 90, avgScore: 85 },
    ],
    series: [
      {
        type: "bar",
        xKey: "question",
        yKey: "individualScore",
        yName: "Individual Score",
        fill: "#3e95cd",
      },
      {
        type: "bar",
        xKey: "question",
        yKey: "avgScore",
        yName: "Average Score",
        fill: "#e5e5e5",
      },
    ],
    axes: [
      { type: "category", position: "bottom" },
      { type: "number", position: "left", title: { text: "Score" }, max: 100 },
    ],
    legend: { position: "top" },
  };

  const lineChartOptions1 = {
    title: { text: "Page Impressions" },
    data: [
      { time: "12am", impressions: 25000 },
      { time: "6am", impressions: 50000 },
      { time: "12pm", impressions: 75000 },
      { time: "6pm", impressions: 100000 },
    ],
    series: [{ type: "line", xKey: "time", yKey: "impressions", stroke: "#3e95cd" }],
    axes: [
      { type: "category", position: "bottom" },
      { type: "number", position: "left" },
    ],
  };

  const lineChartOptions2 = {
    title: { text: "Engaged Users" },
    data: [
      { time: "12am", users: 20000 },
      { time: "6am", users: 40000 },
      { time: "12pm", users: 60000 },
      { time: "6pm", users: 80000 },
    ],
    series: [{ type: "line", xKey: "time", yKey: "users", stroke: "#ff4c4c" }],
    axes: [
      { type: "category", position: "bottom" },
      { type: "number", position: "left" },
    ],
  };

  return (
    <>
      <IndividualHeader />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
        {/* <ThemeProvider theme={theme}>
          <Grid container spacing={3} sx={{ mb: 4 }}>
            {assessmentData.map((data, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    backgroundColor: cardBackgroundColors[index % cardBackgroundColors.length],
                    borderRadius: "12px",
                    padding: "16px",
                    textAlign: "center",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.neutral.main }}>
                      {data.title}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                      <CircularProgress
                        variant="determinate"
                        value={data.score}
                        size={50}
                        thickness={5}
                        sx={{ color: theme.palette.accent.main }}
                      />
                      <Typography variant="body2" sx={{ ml: 1, color: theme.palette.neutral.main }}>
                        {data.score}% Completed
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Typography variant="body2" sx={{ color: theme.palette.accent.main, mr: 0.5 }}>
                        Peer Avg Score: {data.avgScore}
                      </Typography>
                      <Tooltip title="Average score of all participants" placement="top">
                        <InfoIcon fontSize="small" sx={{ color: theme.palette.accent.main }} />
                      </Tooltip>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3, boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
                <Typography variant="h6" sx={{ mb: 2, color: theme.palette.neutral.main }}>
                  Question-wise Comparison
                </Typography>
                <AgCharts options={barChartOptions} style={{ width: "100%", height: "300px" }} />
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ mb: 2, p: 3, boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
                <Typography variant="h6" sx={{ color: theme.palette.neutral.main }}>
                  Page Impressions
                </Typography>
                <AgCharts options={lineChartOptions1} style={{ width: "100%", height: "200px" }} />
              </Card>
              <Card sx={{ p: 3, boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
                <Typography variant="h6" sx={{ color: theme.palette.neutral.main }}>
                  Engaged Users
                </Typography>
                <AgCharts options={lineChartOptions2} style={{ width: "100%", height: "200px" }} />
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ mt: -20 }}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3, boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
                <Typography variant="h6" sx={{ mb: 2, color: theme.palette.neutral.main }}>
                  Detailed Question-wise Answers
                </Typography>
                {questionAnswerData.slice(0, showMore ? questionAnswerData.length : 3).map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: theme.palette.background.default,
                      borderRadius: "8px",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold", color: theme.palette.neutral.main }}>
                        {item.question}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        Answer: {item.answer}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <StarRateIcon sx={{ mr: 1, color: theme.palette.accent.main }} />
                      <Typography variant="body2">Score: {item.score}%</Typography>
                      <AccessTimeIcon sx={{ mx: 1, color: theme.palette.accent.main }} />
                      <Typography variant="body2">Time Taken: {item.time}</Typography>
                    </Box>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  onClick={toggleShowMore}
                  sx={{ mt: 2, backgroundColor: theme.palette.accent.main, color: "white" }}
                >
                  {showMore ? "Show Less" : "More"}
                </Button>
              </Card>
            </Grid>
          </Grid>
        </ThemeProvider> */}
        <Dashboard />
        {/* <MainReport /> */}
      </Container>
    </>
  );
};

export default AssessmentDashboard;
