import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import history from "./useHistory.js"; // <-- import

const useBlocker = (blocker, when = true) => {
  useEffect(() => {
    if (!when) return;
    const unblock = history.block((tx) => { // <-- use history
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        }
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [blocker, when]);
};

export default useBlocker;