// src/components/Footer.js
import React from 'react';
import { useTheme } from '../context/ThemeContext'; // Import the custom ThemeContext

const Footer = () => {
  const { theme } = useTheme(); // Access the current theme

  return (
    <footer className={`py-12 ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} transition-colors duration-300`}>
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <h3 className="text-2xl font-bold mb-4 md:mb-0">Join the Future of Recruitment</h3>
          <form className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
            <input
              type="email"
              placeholder="Enter your email"
              className={`px-4 py-2 rounded-full outline-none focus:ring-2 focus:ring-orange-500 w-full md:w-auto ${theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-900'}`}
            />
            <button className="bg-orange-500 px-6 py-2 rounded-full hover:bg-orange-400 transition-colors">
              Join Waiting List
            </button>
          </form>
        </div>

        <div className={`grid grid-cols-1 md:grid-cols-3 gap-8 mb-8 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-700'}`}>
          <div>
            <h4 className="text-lg font-semibold mb-4">About OptHyr</h4>
            <p>
              OptHyr is an advanced recruitment platform designed to streamline the hiring process for companies by leveraging AI-driven insights, automated interviews, and seamless candidate matching.
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Features</h4>
            <ul className="space-y-2">
              <li>AI-Powered Resume Parsing</li>
              <li>Job Description Creation</li>
              <li>Automated Job Matching</li>
              <li>Interactive AI Interviews</li>
              <li>User and Company Management</li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <p>For support or inquiries, please contact us:</p>
            <p>Email: support@optimaaihire.com</p>
            <p>Phone: +1 (555) 123-4567</p>
          </div>
        </div>

        <div className={`border-t pt-6 text-center ${theme === 'dark' ? 'border-gray-700 text-gray-500' : 'border-gray-300 text-gray-600'}`}>
          <p>© 2024 OptHyr. All rights reserved.</p>
          <div className="flex justify-center space-x-4 mt-4">
            <a href="#" className={`hover:${theme === 'dark' ? 'text-white' : 'text-black'}`}>Terms & Conditions</a>
            <a href="#" className={`hover:${theme === 'dark' ? 'text-white' : 'text-black'}`}>Privacy Policy</a>
            <a href="#" className={`hover:${theme === 'dark' ? 'text-white' : 'text-black'}`}>Cookie Policy</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
