import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useBlocker from './UseBlocker';  // Assuming you have the `useBlocker` hook

const useCallbackPrompt = (when) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false); // To control the visibility of the confirmation dialog
  const [lastLocation, setLastLocation] = useState(null); // Store the last attempted location
  const [confirmedNavigation, setConfirmedNavigation] = useState(false); // Flag to track if navigation is confirmed

  // Cancel the navigation when the user cancels
  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  // Block navigation when the user tries to leave
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // Block navigation only if the user has not confirmed yet
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true); // Show the confirmation dialog
        setLastLocation(nextLocation); // Store the location the user is trying to navigate to
        return false; // Block the navigation
      }
      return true; // Allow navigation if confirmed
    },
    [confirmedNavigation, location.pathname]
  );

  // Confirm navigation when the user confirms
  const confirmNavigation = useCallback(() => {
    setShowPrompt(false); // Hide the prompt
    setConfirmedNavigation(true); // Mark the navigation as confirmed
  }, []);

  // Once the navigation is confirmed, redirect to the last location
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname); // Perform the navigation
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  // Use the `useBlocker` hook to block the navigation
  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
};

export default useCallbackPrompt;
