module.exports = {
    /* Testing */
    // API_URL: 'https://aihiring-api-qa.techoptima.com/api/', 
    /* Development */
    // API_URL: 'https://aiforhire-backend.onrender.com/api/',
    // API_URL: 'https://backend.optimaaihire.com/api/',
    API_URL: process.env.REACT_APP_BACKEND_URL,
    /* Development */
    Socket_URL: 'https://aihiring.socket.techoptima.com'
    /* Testing */
    // Socket_URL: 'https://aihiringqa.socket.techoptima.com'
}
