import React from 'react';
import { Box, Typography, Link, Paper } from '@mui/material';

const QuickLinksSidebar = () => {
  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: '8px', position: 'sticky', top: 80 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
        Quick links
      </Typography>
      <Box>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#account-info" color="primary" underline="hover">Account Info</Link>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#account-security" color="primary" underline="hover">Account Security</Link>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#login-info" color="primary" underline="hover">Login Info</Link>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#two-step-verification" color="primary" underline="hover">2-Step Verification</Link>
        </Typography>
        {/* <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#social-logins" color="primary" underline="hover">Social Logins</Link>
        </Typography> */}
        
        {/* New Links for Key Skills, Employment, and Education */}
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#key-skills" color="primary" underline="hover">Key Skills</Link>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#employment" color="primary" underline="hover">Employment</Link>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#education" color="primary" underline="hover">Education</Link>
        </Typography>

        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#update-resume" color="primary" underline="hover">Update Resume</Link>
        </Typography>
        {/* <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#email-preferences" color="primary" underline="hover">Email Preferences</Link>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#login-activity" color="primary" underline="hover">Login Activity</Link>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#privacy-preferences" color="primary" underline="hover">Privacy Preferences</Link>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Link href="#close-account" color="primary" underline="hover">Close Account</Link>
        </Typography> */}
      </Box>
    </Paper>
  );
};

export default QuickLinksSidebar;
