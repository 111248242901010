// import React from 'react';
// import { Box, Typography, Grid, Button, Divider, IconButton, Tooltip } from '@mui/material';
// import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
// import SmsIcon from '@mui/icons-material/Sms';
// import LockIcon from '@mui/icons-material/Lock';
// import EmailIcon from '@mui/icons-material/Email';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import NewReleasesIcon from '@mui/icons-material/NewReleases';

// const VerificationMethod = ({ icon, title, description, newBadge }) => (
//     <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ py: 1 }}>
//         <Box display="flex" alignItems="center" gap={2}>
//             <Box>{icon}</Box>
//             <Box>
//                 <Typography variant="body1" fontWeight="bold" display="flex" alignItems="center" gap={1}>
//                     {title} {newBadge && <NewReleasesIcon color="primary" fontSize="small" />}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary">
//                     {description}
//                 </Typography>
//             </Box>
//         </Box>
//         <Button variant="text" color="primary" sx={{ textTransform: 'none' }}>
//             Enable
//         </Button>
//     </Box>
// );

// const TwoStepVerification = () => (
//     <Box>
//         <Typography variant="h6" component="h2" fontWeight="bold" sx={{ mb: 1 }}>
//             2-step verification
//             <Tooltip title="Protect your account with an extra layer of security" arrow>
//                 <IconButton size="small" color="primary">
//                     <InfoOutlinedIcon fontSize="small" />
//                 </IconButton>
//             </Tooltip>
//         </Typography>
//         <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
//             Protect your account by adding an extra layer of security when you log in with an email and password.
//         </Typography>

//         <Divider sx={{ my: 1 }} />

//         <VerificationMethod
//             icon={<AppRegistrationIcon color="primary" />}
//             title="Verify logins with the App"
//             description="Get a push notification from the app to verify logins."
//             newBadge
//         />
        
//         <Divider sx={{ my: 1 }} />

//         <VerificationMethod
//             icon={<SmsIcon color="primary" />}
//             title="Verify logins by phone (SMS)"
//             description="Get a verification code sent to your mobile phone."
//         />

//         <Divider sx={{ my: 1 }} />

//         <VerificationMethod
//             icon={<LockIcon color="primary" />}
//             title="Verify logins with an authenticator app"
//             description="Make sure you have an authenticator app installed on your mobile before you start."
//         />

//         <Divider sx={{ my: 1 }} />

//         <VerificationMethod
//             icon={<EmailIcon color="primary" />}
//             title="Verify logins by email"
//             description="Receive a verification code via email to confirm logins."
//         />
//     </Box>
// );

// export default TwoStepVerification;

import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Tooltip, Switch, Paper, Grid, Modal, Button, TextField, CircularProgress } from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SmsIcon from '@mui/icons-material/Sms';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CommonApi from '../../../apis/CommonApi';
import LocalStorage from '../../../utils/LocalStorage';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Verification Method Component (Reusable)
const VerificationMethod = ({ icon, title, description, newBadge, isEnabled, onToggle, status }) => (
    <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 2, height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="start" gap={1}>
                {icon}
                <Box>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        display="flex"
                        alignItems="center"
                        gap={1}
                        sx={{ lineHeight: 1.2 }}
                    >
                        {title} {newBadge && <NewReleasesIcon color="primary" fontSize="small" />}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {description}
                    </Typography>
                    {status && (
                        <Typography variant="body2" color="primary" sx={{ mt: 0.5 }}>
                            Status: Enabled
                        </Typography>
                    )}
                </Box>
            </Box>
            <Switch
                checked={isEnabled}
                onChange={onToggle}
                color="primary"
                inputProps={{ 'aria-label': `${title} toggle` }}
            />
        </Box>
    </Paper>
);

const TwoStepVerification = () => {
    const navigate = useNavigate(); // Hook for navigation
    const [appEnabled, setAppEnabled] = useState(false);
    const [smsEnabled, setSmsEnabled] = useState(false);
    const [authenticatorEnabled, setAuthenticatorEnabled] = useState(false);
    const [emailEnabled, setEmailEnabled] = useState(false);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [qrCodeDataURL, setQRCodeDataURL] = useState('');
    const [secret, setSecret] = useState('');
    const [otpToken, setOtpToken] = useState('');
    const [emailCodeSent, setEmailCodeSent] = useState(false);
    const [emailVerificationToken, setEmailVerificationToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopy = async () => {
      try {
        await navigator.clipboard.writeText(secret);
        setCopySuccess(true);
        // Reset the tooltip after 2 seconds
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error('Failed to copy!', err);
      }
    };
    // Fetch initial verification statuses
    useEffect(() => {
        const fetchVerificationStatus = async () => {
            setLoading(true);
            try {
                const response = await CommonApi.getAccountInformation();
                setAuthenticatorEnabled(response.totpEnabled || false);
                setEmailEnabled(response.emailVerified || false);
            } catch (error) {
                console.error('Error fetching verification statuses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVerificationStatus();
    }, []);

    // Enable TOTP Authenticator39
    const handleEnableAuthenticator = async () => {
        if (authenticatorEnabled) return;

        try {
            setLoading(true);
            const data = await CommonApi.generateTOTPQRCode();
            setQRCodeDataURL(data.qrCodeDataURL);
            setSecret(data.secret);
            setShowQRCodeModal(true);
        } catch (error) {
            console.error('Failed to generate QR code:', error);
        } finally {
            setLoading(false);
        }
    };

    // Verify OTP Token from Authenticator App
    const handleVerifyToken = async () => {
        setLoading(true);
        try {
            const response = await CommonApi.verifyTOTPToken({ token: otpToken });
            alert(response.message);
            setAuthenticatorEnabled(true);
            setShowQRCodeModal(false);

            // Redirect after successful TOTP verification
            navigate('/account-settings');
        } catch (error) {
            console.error('Error verifying token:', error);
            alert('Failed to verify token. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Enable Email Verification
    const handleEnableEmailVerification = async () => {
        setLoading(true);
        try {
            const token = LocalStorage.getAccessToken();
            await CommonApi.sendEmailVerificationCode(token);
            alert('Verification code sent to your email.');
            setEmailCodeSent(true);
        } catch (error) {
            console.error('Error sending email verification code:', error);
            alert('Failed to send verification code.');
        } finally {
            setLoading(false);
        }
    };

    // Verify Email Verification Token
    const handleVerifyEmailToken = async () => {
        setLoading(true);
        try {
            await CommonApi.verifyEmailToken({ token: emailVerificationToken });
            alert('Email verification successful.');
            setEmailEnabled(true); // Update the UI
            setEmailCodeSent(false); // Close the modal

            // Redirect after successful email verification
            navigate('/account-settings');
        } catch (error) {
            console.error('Error verifying email token:', error);
            alert('Failed to verify email token. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper sx={{ p: 3, boxShadow: 3, borderRadius: 2, height: '100%' }}>
            <Typography variant="h6" component="h2" fontWeight="bold" sx={{ mb: 1 }}>
                2-step verification
                <Tooltip title="Protect your account with an extra layer of security" arrow>
                    <IconButton size="small" color="primary">
                        <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Protect your account by adding an extra layer of security when you log in with an email and password.
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <VerificationMethod
                        icon={<LockIcon color="primary" />}
                        title="Verify logins with an authenticator app"
                        description="Make sure you have an authenticator app installed on your mobile before you start."
                        isEnabled={authenticatorEnabled}
                        onToggle={handleEnableAuthenticator}
                        status={authenticatorEnabled}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box sx={{height:emailCodeSent?"auto":"100%"}}>
                    <VerificationMethod
                        icon={<EmailIcon color="primary" />}
                        title="Verify logins by email"
                        description="Receive a verification code via email to confirm logins."
                        isEnabled={emailEnabled}
                        onToggle={!emailEnabled ? handleEnableEmailVerification : null}
                        status={emailEnabled}
                    />
                    {emailCodeSent && (
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                label="Enter Verification Code"
                                value={emailVerificationToken}
                                onChange={(e) => setEmailVerificationToken(e.target.value)}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Button variant="contained" color="primary" onClick={handleVerifyEmailToken} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Verify Code'}
                            </Button>
                        </Box>
                    )}
                    </Box>
                </Grid>
            </Grid>

            {/* Modal for QR Code */}
            <Modal open={showQRCodeModal} onClose={() => setShowQRCodeModal(false)}>
                <Paper sx={{ p: 4, maxWidth: 450, mx: 'auto', mt: 5}}>
                    <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                    <Typography variant="h6">Scan QR Code with an authenticator app</Typography>
                    {qrCodeDataURL && <img src={qrCodeDataURL} alt="QR Code" style={{ margin: '20px 0' }} />}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#f5f5f5',
                            padding: '12px',
                            borderRadius: '8px',
                            fontFamily: 'Monospace, monospace',
                            position: 'relative',
                            overflow:"hidden",
                            maxWidth:"400px"
                        }}
                        >
                        <Typography variant="body2" sx={{ flexGrow: 1,overflow:"hidden" }}>
                            Secret Key: <code>{secret}</code>
                        </Typography>
                        <Tooltip title={copySuccess ? "Copied!" : "Copy to clipboard"}>
                            <IconButton onClick={handleCopy} aria-label="copy secret key">
                            <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        </Box>
                    <TextField
                        label="Enter OTP"
                        value={otpToken}
                        onChange={(e) => setOtpToken(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <Button variant="contained" color="primary" onClick={handleVerifyToken} sx={{ mt: 2 }} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Verify'}
                    </Button>
                    </Box>
                </Paper>
            </Modal>
        </Paper>
    );
};

export default TwoStepVerification;
