// import BaseApi from "./BaseApi";
// import APIURL from "../config/development";
// import LocalStorage from "../utils/LocalStorage";
// import axios from "axios";

// class CommonApi {
//   async Login(data) {
//     const response = await BaseApi.postWithData(
//       `${APIURL.API_URL}auth/login`,
//       data
//     );
//     if (response && response.data && response.data.company) {
//       LocalStorage.setCompanyData(response.data.company);
//     }
//     if (response.status === 200 && response.data) {
//       // Store the user ID and other user data in local storage
//       LocalStorage.setUserData({
//         email: response.data.email, // assuming email is part of the response data
//         id: response.data.id, // make sure response.data.id contains the user ID
//       });
//       LocalStorage.setAccessToken(response.data.token); // Store the token
//       LocalStorage.setSessionFlag(true); // Set session flag to true
//     }
//     return response;
//   }

//   Logout() {
//     const token = LocalStorage.getAccessToken();
//     LocalStorage.clearAll(); // Clear all data on logout
//     return BaseApi.postWithToken(`${APIURL.API_URL}auth/logout`, {}, token);
//   }
//   async getCompanies(token, page, limit, sortBy, order, search) {
//     try {
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}companies?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}&search=${search}`,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   createCompany(data) {
//     const token = LocalStorage.getAccessToken();
//     return BaseApi.postWithToken(`${APIURL.API_URL}companies`, data, token);
//   }

//   async getProfilesByCompany(companyId, token) {
//     try {
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}candidate-profiles/company/${companyId}`,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async getJobsByCompany(
//     companyId,
//     token,
//     page = 1,
//     limit = 10,
//     sortOrder = "desc",
//     searchQuery = "",
//     roleName = "",  // Add roleName parameter
//     domain = "",    // Add domain parameter
//     city = ""       // Add city parameter
//   ) {
//     try {
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}jobs/company/${companyId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&searchQuery=${searchQuery}&roleName=${roleName}&domain=${domain}&city=${city}`,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async googleLogin(data) {
//     try {
//       const response = await BaseApi.postWithData(`${APIURL.API_URL}auth/google-login`, data);
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async createJobDescription(data, token) {
//     console.log("Calling createJobDescription API with data:", data); // Debugging log
//     try {
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}jobs`,
//         data,
//         token
//       );
//       console.log("createJobDescription response:", response); // Debugging log
//       return response.data;
//     } catch (error) {
//       console.error("Error in createJobDescription:", error);
//       throw error;
//     }
//   }

//   // async parseResume(data) {
//   //   try {
//   //     const token = LocalStorage.getAccessToken();
//   //     const response = await BaseApi.postWithToken(
//   //       `${APIURL.API_URL}resume-parser/upload`,
//   //       data,
//   //       token
//   //     );
//   //     return response.data;
//   //   } catch (error) {
//   //     console.error("Error in parseResume:", error);
//   //     throw error;
//   //   }
//   // }

//   async sendShortlistedEmail(data) {
//     try {
//       const response = await BaseApi.postWithData(
//         `${APIURL.API_URL}email/send-shortlisted-email`,
//         data
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // async scheduleInterview(data) {
//   //   const token = LocalStorage.getAccessToken();
//   //   try {
//   //     const response = await BaseApi.postWithToken(`${APIURL.API_URL}interview/schedule`, data, token);
//   //     return response.data;
//   //   } catch (error) {
//   //     throw error;
//   //   }
//   // }

//   async scheduleInterview(interviewDetails) {
//     const token = LocalStorage.getAccessToken();
//     console.log("Token:", token); // Debugging log
//     const response = await BaseApi.postWithToken(
//       `${APIURL.API_URL}interview/schedule`,
//       interviewDetails,
//       token
//     );
//     return response.data;
//   }

//   // async parseResume(data, token) {
//   //   return BaseApi.postWithToken(`${APIURL.API_URL}resume-parser/parse`, data, token);
//   // }

//   // async parseResume(data) {
//   //   const token = LocalStorage.getAccessToken();
//   //   try {
//   //     const response = await BaseApi.postWithToken(`${APIURL.API_URL}resume-parser/parse`, data, token);
//   //     return response.data;
//   //   } catch (error) {
//   //     throw error;
//   //   }
//   // }

//   //  async parseResume(formData) {
//   //   const response = await BaseApi.postFormData(`${APIURL.API_URL}resume-parser/upload`, formData, {
//   //     headers: {
//   //       'Content-Type': 'multipart/form-data'
//   //     }
//   //   });
//   //   return response.data;
//   // };

//   async getUserProfile(token) {
//     try {
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}users/me`,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   getCurrentUser(token) {
//     return BaseApi.getWithToken(`${APIURL.API_URL}users/me`, token);
//   }

//   async createCandidateProfile(data) {
//     const token = LocalStorage.getAccessToken();
//     try {
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}candidate-profiles`,
//         data,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async createCompanyUserProfile(data) {
//     const token = LocalStorage.getAccessToken();
//     try {
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}candidate-profiles/company-users`,
//         data,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // async createCandidateProfile(data, token) {
//   //   try {
//   //     const response = await BaseApi.postWithToken(
//   //       `${APIURL.API_URL}candidate-profiles`,
//   //       data,
//   //       token
//   //     );
//   //     return response.data;
//   //   } catch (error) {
//   //     throw error;
//   //   }
//   // }

//   async updateCandidateProfile(id, data, token) {
//     return BaseApi.putWithToken(
//       `${APIURL.API_URL}candidate-profiles/${id}`,
//       data,
//       token
//     );
//   }

//   async deleteCandidateProfile(id, token) {
//     return BaseApi.deleteWithToken(
//       `${APIURL.API_URL}candidate-profiles/${id}`,
//       token
//     );
//   }

//   // async createCompanyUserProfile(data, token) {
//   //   try {
//   //     const response = await BaseApi.postWithToken(
//   //       `${APIURL.API_URL}candidate-profiles/company-users`,
//   //       data,
//   //       token
//   //     );
//   //     return response.data;
//   //   } catch (error) {
//   //     throw error;
//   //   }
//   // }

//   postWithToken(url, data, token) {
//     return BaseApi.postWithToken(url, data, token);
//   }

//   async initiateGpt4oConversation(audioData) {
//     const token = LocalStorage.getAccessToken();
//     return this.postWithToken(
//       `${APIURL.API_URL}gpt4o-conversation`,
//       audioData,
//       token
//     );
//   }
//   // async getJobs(token) {
//   //   const response = await BaseApi.getWithToken(`${APIURL.API_URL}jobs`, token);
//   //   return response;
//   //   console.log("CommonAPI response", response);
//   // }

//   async getJobs(token, companyId) {
//     const response = await BaseApi.getWithToken(
//       `${APIURL.API_URL}jobs?companyId=${companyId}`,
//       token
//     );
//     return response;
//   }

//   async createJob(data) {
//     try {
//       const token = LocalStorage.getAccessToken();
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}jobs`,
//         data,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async updateJob(jobId, updateData) {
//     try {
//       const token = LocalStorage.getAccessToken();
//       const response = await BaseApi.putWithToken(
//         `${APIURL.API_URL}jobs/${jobId}`,
//         updateData,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async addCandidates(jobId, updateData, token) {
//     // const token = LocalStorage.getAccessToken();
//     try {
//       const response = await BaseApi.putWithToken(
//         `${APIURL.API_URL}jobs/${jobId}/candidates`,
//         updateData,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async parseResume(formData) {
//     try {
//       const token = LocalStorage.getAccessToken();
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}resume-parser/upload`,
//         formData,
//         token,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             // 'Authorization': `Bearer ${token}`
//           },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       // console.error('Error parsing resumes:', error);
//       throw error;
//     }
//   }

//   async getJobById(jobId) {
//     const token = LocalStorage.getAccessToken();
//     const response = await BaseApi.getWithToken(
//       `${APIURL.API_URL}jobs/${jobId}`,
//       token
//     );
//     console.log("response job", response.data);
//   }

//   async getResumesByJobId(jobId) {
//     const token = LocalStorage.getAccessToken();
//     const response = await BaseApi.getWithToken(
//       `${APIURL.API_URL}jobs/${jobId}/resumes`,
//       token
//     );
//     return response.data;
//   }

//   async getCountries() {
//     try {
//       const response = await axios.get(`${APIURL.API_URL}country/countries`);
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async getStates(countryCode) {
//     try {
//       const response = await axios.get(
//         `${APIURL.API_URL}country/states/${countryCode}`
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async generateChatResponse(currentPrompt, job, resume, conversationHistory = [], maxTokens) {
//     try {
//       const response = await axios.post(`${APIURL.API_URL}openai/generate`, {
//         prompt: currentPrompt,
//         job: job,
//         resume: resume,
//         conversationHistory,
//         maxTokens,
//       });
//       return response.data;
//     } catch (error) {
//       console.error("Error generating chat response:", error);
//       throw error;
//     }
//   }

//   async initializeInterview(jobDescription, candidateName) {
//     const token = LocalStorage.getAccessToken();
//     try {
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}openai/initialize`,
//         { jobDescription, candidateName },
//         token
//       );
//       return response.data;
//     } catch (error) {
//       console.error("Error initializing interview:", error);
//       throw error;
//     }
//   }

//   // Method to generate a response during the interview
//   // async generateChatResponse(prompt, jobDescription, resume) {
//   //   try {
//   //     const token = LocalStorage.getAccessToken();
//   //     const response = await axios.post(
//   //       `${APIURL.API_URL}openai/generate`,
//   //       { prompt, jobDescription, resume },
//   //       {
//   //         headers: {
//   //           Authorization: `Bearer ${token}`,
//   //         },
//   //       }
//   //     );
//   //     return response.data;
//   //   } catch (error) {
//   //     console.error('Error generating chat response:', error);
//   //     throw error;
//   //   }
//   // }

//   // Method to get the current status of the interview
//   async getInterviewStatus() {
//     const token = LocalStorage.getAccessToken();
//     try {
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}openai/status`,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       console.error("Error fetching interview status:", error);
//       throw error;
//     }
//   }

//   // Method to end the interview session
//   async endInterview() {
//     const token = LocalStorage.getAccessToken();
//     try {
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}openai/end`,
//         {},
//         token
//       );
//       return response.data;
//     } catch (error) {
//       console.error("Error ending interview:", error);
//       throw error;
//     }
//   }

//   async scheduleInterview(interviewDetails) {
//     const token = LocalStorage.getAccessToken();
//     try {
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}interview/schedule`,
//         interviewDetails,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       console.error("Error scheduling interview:", error);
//       throw error;
//     }
//   }

//   async updateResumeStatus(resumeId, status, token) {
//     try {
//       const response = await BaseApi.putWithToken(
//         `${APIURL.API_URL}interview/parsed-resumes/update-status`,
//         { resumeId, status },
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async getCompanyWithRoleCounts(companyId, token) {
//     try {
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}companies/find/${companyId}/with-role-counts`,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }

//   async getDashboardData() {
//     const token = LocalStorage.getAccessToken(); // Retrieve the access token from local storage
//     try {
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}dashboard-data`,
//         token
//       );
//       return response.data; // Return the dashboard data
//     } catch (error) {
//       throw error; // Handle errors as needed
//     }
//   }

//   async getJobsByUser() {
//     const token = LocalStorage.getAccessToken(); // Retrieve the access token from local storage
//     try {
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}jobs/user-jobs`,
//         token
//       );
//       return response.data; // Return the dashboard data
//     } catch (error) {
//       throw error; // Handle errors as needed
//     }
//   }

//   // async getJobsByUser(token) {
//   //   const response = await BaseApi.getWithToken(`${APIURL.API_URL}user-jobs`, token);
//   //   return response.data;
//   // }

//   async getParsedResumesByUser(token) {
//     const response = await BaseApi.getWithToken(
//       `${APIURL.API_URL}user-resumes`,
//       token
//     );
//     return response.data;
//   }

//   async getCandidateProfileDetails(candidateProfileId) {
//     try {
//       const token = LocalStorage.getAccessToken(); // Retrieve the access token
//       const response = await BaseApi.getWithToken(
//         `${APIURL.API_URL}candidateProfile/${candidateProfileId}/details`,
//         token
//       );
//       return response.data;
//     } catch (error) {
//       console.error("Error fetching candidate profile details:", error);
//       throw error;
//     }
//   }

//   async generateResponseWithAudio(data) {
//     const token = LocalStorage.getAccessToken();
//     return BaseApi.postWithToken(
//       `${APIURL.API_URL}openai/generate-with-audio`,
//       data,
//       token
//     );
//   }

//   // Function to register an employer
//   async registerEmployer(data) {
//     try {
//       const response = await axios.post(
//         `${APIURL.API_URL}employers/register`,
//         data
//       );
//       if (response.status === 201) {
//         LocalStorage.setAccessToken(response.data.token); // Store the token if registration is successful
//       }
//       return response;
//     } catch (error) {
//       console.error("Error during employer registration:", error);
//       throw error;
//     }
//   }

//   // Employer login API
//   async loginEmployer(data) {
//     try {
//       const response = await axios.post(
//         `${APIURL.API_URL}employers/login`,
//         data
//       ); // Assuming /employers/login is your login endpoint
//       return response;
//     } catch (error) {
//       console.error("Error during login:", error);
//       throw error;
//     }
//   }

//   async createJobDescription(data) {
//     try {
//       const response = await BaseApi.post(`${APIURL.API_URL}jobs/create`, data);
//       return response;
//     } catch (error) {
//       console.error("Error creating job description:", error);
//       throw error;
//     }
//   }
//   async uploadResumes(data) {
//     try {
//       const response = await BaseApi.post(
//         `${APIURL.API_URL}resumes/upload`,
//         data
//       );
//       return response;
//     } catch (error) {
//       console.error("Error uploading resumes:", error);
//       throw error;
//     }
//   }

//   // Interviewee registration API
//   async registerInterviewee(data) {
//     try {
//       const response = await axios.post(
//         `${APIURL.API_URL}interviewees/register`,
//         data
//       );
//       if (response.status === 201) {
//         LocalStorage.setAccessToken(response.data.token); // Store the token if registration is successful
//       }
//       return response;
//     } catch (error) {
//       console.error("Error during interviewee registration:", error);
//       throw error;
//     }
//   }

//   // Interviewee login API
//   async loginInterviewee(data) {
//     try {
//       const response = await axios.post(
//         `${APIURL.API_URL}interviewees/login`,
//         data
//       );
//       return response;
//     } catch (error) {
//       console.error("Error during interviewee login:", error);
//       throw error;
//     }
//   }

//   async uploadIntervieweeFiles(formData, token) {
//     try {
//       const response = await BaseApi.postWithToken(
//         `${APIURL.API_URL}interviewees/upload-files`,
//         formData,
//         token,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   }

//    // Function to initialize the chatbot interview
//   //  async initializeChatbotInterview(data) {
//   //   try {
//   //     const token = LocalStorage.getAccessToken(); // Get the token from local storage
//   //     const response = await BaseApi.postWithToken(
//   //       `${APIURL.API_URL}interviewees/initialize-interview`,
//   //       data,
//   //       token
//   //     );
//   //     return response.data;
//   //   } catch (error) {
//   //     console.error("Error initializing chatbot interview:", error);
//   //     throw error;
//   //   }
//   // }

//   // // Function to continue the chatbot interview
//   // async continueChatbotInterview(data) {
//   //   try {
//   //     const token = LocalStorage.getAccessToken(); // Get the token from local storage
//   //     const response = await BaseApi.postWithToken(
//   //       `${APIURL.API_URL}interviewees/continue-interview`,
//   //       data,
//   //       token
//   //     );
//   //     return response.data;
//   //   } catch (error) {
//   //     console.error("Error continuing chatbot interview:", error);
//   //     throw error;
//   //   }
//   // }

//     // Method to initialize the chatbot interview
//     async initializeChatbotInterview(data) {
//       return BaseApi.postWithToken(`${APIURL.API_URL}interviewee/initialize-chatbot-interview`, data);
//     }

//     // Method to continue the chatbot interview
//     async continueChatbotInterview(data) {
//       return BaseApi.postWithToken(`${APIURL.API_URL}interviewee/continue-chatbot-interview`, data);
//     }

//     async saveReportCard(reportData) {
//       try {
//         const token = LocalStorage.getAccessToken();
//         const response = await BaseApi.postWithToken(
//           `${APIURL.API_URL}report-card/save`,
//           reportData,
//           token
//         );
//         return response.data;
//       } catch (error) {
//         console.error('Error saving report card:', error);
//         throw error;
//       }
//     }

//     async getReportCardByCandidateProfileId(candidateProfileId, token) {
//       try {
//         const response = await BaseApi.getWithToken(
//           `${APIURL.API_URL}report-card/candidate/${candidateProfileId}`,
//           token
//         );
//         return response.data;
//       } catch (error) {
//         console.error("Error fetching report card:", error);
//         throw error;
//       }
//     }

//     // async generateChatResponse(answers) {
//     //   try {
//     //     const response = await BaseApi.postWithData(
//     //       `${APIURL.API_URL}jobs/chatbotjob`,
//     //       answers
//     //     );
//     //     return response.data;
//     //   } catch (error) {
//     //     console.error("Error generating chat response:", error);
//     //     throw error;
//     //   }
//     // }

//     async generateJobDescriptionWithAnswers(answers) {
//       try {
//         const response = await BaseApi.postWithData(
//           `${APIURL.API_URL}jobs/chatbotjob`, // Ensure this endpoint matches your backend route
//           answers
//         );
//         return response.data;
//       } catch (error) {
//         console.error("Error generating job description:", error);
//         throw error;
//       }
//     }
//     async parseJobDescriptionFromFile(formData) {
//       const token = LocalStorage.getAccessToken(); // Retrieve the access token from local storage
//       try {
//         const response = await BaseApi.postWithToken(
//           `${APIURL.API_URL}jobs/parse-job-description`,
//           formData,
//           token,
//           {
//             headers: {
//               "Content-Type": "multipart/form-data",
//             },
//           }
//         );
//         return response.data;
//       } catch (error) {
//         console.error('Error parsing job description:', error);
//         throw error;
//       }
//     }

//   // Other API methods...
// }

// export default new CommonApi();

import BaseApi from "./BaseApi";
import APIURL from "../config/development";
import LocalStorage from "../utils/LocalStorage";
import axios from "axios";

class CommonApi {
  async Login(data) {
    const response = await BaseApi.postWithData(
      `${APIURL.API_URL}auth/login`,
      data
    );
    if (response && response.data && response.data.company) {
      LocalStorage.setCompanyData(response.data.company);
    }
    if (response.status === 200 && response.data) {
      // Store the user ID and other user data in local storage
      LocalStorage.setUserData({
        email: response.data.email, // assuming email is part of the response data
        id: response.data.id, // make sure response.data.id contains the user ID
      });
      LocalStorage.setAccessToken(response.data.token); // Store the token
      LocalStorage.setSessionFlag(true); // Set session flag to true
    }
    return response;
  }

  Logout() {
    const token = LocalStorage.getAccessToken();
    LocalStorage.clearAll(); // Clear all data on logout
    return BaseApi.postWithToken(`${APIURL.API_URL}auth/logout`, {}, token);
  }
  async getCompanies(token, page, limit, sortBy, order, search) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}companies?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}&search=${search}`,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  createCompany(data) {
    const token = LocalStorage.getAccessToken();
    return BaseApi.postWithToken(`${APIURL.API_URL}companies`, data, token);
  }

  async getProfilesByCompany(companyId, token) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}candidate-profiles/company/${companyId}`,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //=========================individual related code starts from here================================

  async registerIndividual(data) {
    try {
      const response = await BaseApi.postWithData(
        `${APIURL.API_URL}auth/register-individual`, // Update this with your actual endpoint
        data
      );

      if (response.status === 201) {
        const { token, userId, role, user } = response.data;
        LocalStorage.setAccessToken(token);
        LocalStorage.setUserData({ token, userId, role, user });
        LocalStorage.setSessionFlag(true); // Set session flag to true
      }

      return response;
    } catch (error) {
      console.error("Error during individual registration:", error);
      throw error; // Rethrow the error for further handling
    }
  }

  async getLinkedInAuthUrl() {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}individual/linkedin/auth-url`,
      token
    );
    return response.data.url;
  }

  async initiateLinkedInOAuth() {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}individual/linkedin/oauth`,
      token
    );
    return response.data.url; // Returns the LinkedIn OAuth URL
  }

  // Disable LinkedIn
  async disableLinkedIn() {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.postWithToken(
      `${APIURL.API_URL}individual/linkedin/disable`,
      {},
      token
    );
    return response.data;
  }

  // async updateIndividualAccountInfo(token, data) {
  //   try {
  //     const response = await BaseApi.putWithToken(
  //       `${APIURL.API_URL}individual/update-account-info`,
  //       data,
  //       token
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error updating individual account information:", error);
  //     throw error;
  //   }
  // }

  // Fetch individual account information, including profile completion percentage
  async getAccountInformation(token) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}individual/account-info`,
        token
      );
      return response.data; // Response will contain `profileCompletion` and other info
    } catch (error) {
      console.error("Error fetching account information:", error);
      throw error;
    }
  }

  // Update individual account information
  async updateIndividualAccountInfo(token, data) {
    try {
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}individual/update-account-info`,
        data,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error updating individual account information:", error);
      throw error;
    }
  }

  // Update individual skills
  async updateSkills(skills) {
    try {
      const token = LocalStorage.getAccessToken();
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}individual/update-skills`, // Make sure this endpoint matches your backend route
        { skills },
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error in updateSkills API call:", error);
      throw error;
    }
  }

  async updateEducation(educationData) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}individual/update-education`,
        educationData,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error updating education:", error);
      throw error;
    }
  }

  async uploadProfileImage(token, formData) {
    try {
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}individual/upload-profile-image`,
        formData,
        token // Ensure token is passed
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading profile image:", error.response?.data || error.message);
      throw error;
    }
  }

  // Send email verification request
  async sendVerificationEmail(user) {
    const token = LocalStorage.getAccessToken();
    try {
      // Send only necessary fields in the request body
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}users/send-verification-email`,
        { email: user.email, name: user.name }, // Ensure only relevant fields are sent
        token
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error sending verification email:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Verify email based on the token in the URL
  async verifyEmail(token) {
    const response = await BaseApi.get(
      `${APIURL.API_URL}user/verify-email?token=${token}`
    );
    return response.data;
  }

  //=========================individual related code ends here================================

  // Method to login an individual
  async loginIndividual(data) {
    try {
      const response = await BaseApi.postWithData(
        `${APIURL.API_URL}auth/login`, // Update this with your actual login endpoint
        data
      );

      if (response.status === 200) {
        const { token, userId, role, user } = response.data;
        LocalStorage.setAccessToken(token);
        LocalStorage.setUserData({ token, userId, role, user });
        LocalStorage.setSessionFlag(true); // Set session flag to true
      }

      return response;
    } catch (error) {
      console.error("Error during individual login:", error);
      throw error; // Rethrow the error for further handling
    }
  }

  async updateEmployment(employmentData) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}individual/update-employment`,
        employmentData,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error updating employment:", error);
      throw error;
    }
  }

  async getEmployment() {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(`${APIURL.API_URL}individual/get-employment`, token);
    return response.data;
  }

  async getAccountInformation() {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}individual/account-info`,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching account information:", error);
      throw error;
    }
  }

  async getSocialLoginStatus() {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}individual/status`,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching social login status:", error);
      throw error;
    }
  }

  // Enable LinkedIn account
  async enableLinkedIn(linkedInName) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}individual/linkedin/enable`,
        { linkedInName },
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error enabling LinkedIn:", error);
      throw error;
    }
  }

  // Disable LinkedIn account
  async disableLinkedIn() {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}individual/linkedin/disable`,
        {},
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error disabling LinkedIn:", error);
      throw error;
    }
  }

  // Enable Twitter account
  async enableTwitter(twitterName) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}individual/twitter/enable`,
        { twitterName },
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error enabling Twitter:", error);
      throw error;
    }
  }

  // Disable Twitter account
  async disableTwitter() {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}individual/twitter/disable`,
        {},
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error disabling Twitter:", error);
      throw error;
    }
  }

  // async updateAccountInformation(data) {
  //   const token = LocalStorage.getAccessToken();
  //   try {
  //     const response = await BaseApi.putWithToken(
  //       `${APIURL.API_URL}individual/update-account-info`,
  //       data,
  //       token
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async getJobsByCompany(
    companyId,
    token,
    page = 1,
    limit = 10,
    sortOrder = "desc",
    searchQuery = "",
    roleName = "", // Add roleName parameter
    domain = "", // Add domain parameter
    city = "" // Add city parameter
  ) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}jobs/company/${companyId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&searchQuery=${searchQuery}&roleName=${roleName}&domain=${domain}&city=${city}`,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async googleLogin(data) {
    try {
      const response = await BaseApi.postWithData(
        `${APIURL.API_URL}auth/google-login`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createJobDescription(data, token) {
    console.log("Calling createJobDescription API with data:", data); // Debugging log
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}jobs`,
        data,
        token
      );
      console.log("createJobDescription response:", response); // Debugging log
      return response.data;
    } catch (error) {
      console.error("Error in createJobDescription:", error);
      throw error;
    }
  }

  // async parseResume(data) {
  //   try {
  //     const token = LocalStorage.getAccessToken();
  //     const response = await BaseApi.postWithToken(
  //       `${APIURL.API_URL}resume-parser/upload`,
  //       data,
  //       token
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error in parseResume:", error);
  //     throw error;
  //   }
  // }

  async sendShortlistedEmail(data) {
    try {
      const response = await BaseApi.postWithData(
        `${APIURL.API_URL}email/send-shortlisted-email`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async scheduleInterview(data) {
  //   const token = LocalStorage.getAccessToken();
  //   try {
  //     const response = await BaseApi.postWithToken(`${APIURL.API_URL}interview/schedule`, data, token);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async scheduleInterview(interviewDetails) {
    const token = LocalStorage.getAccessToken();
    console.log("Token:", token); // Debugging log
    const response = await BaseApi.postWithToken(
      `${APIURL.API_URL}interview/schedule`,
      interviewDetails,
      token
    );
    return response.data;
  }

  // async parseResume(data, token) {
  //   return BaseApi.postWithToken(`${APIURL.API_URL}resume-parser/parse`, data, token);
  // }

  // async parseResume(data) {
  //   const token = LocalStorage.getAccessToken();
  //   try {
  //     const response = await BaseApi.postWithToken(`${APIURL.API_URL}resume-parser/parse`, data, token);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  //  async parseResume(formData) {
  //   const response = await BaseApi.postFormData(`${APIURL.API_URL}resume-parser/upload`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   });
  //   return response.data;
  // };

  async getUserProfile(token) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}users/me`,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  getCurrentUser(token) {
    return BaseApi.getWithToken(`${APIURL.API_URL}users/me`, token);
  }

  async createCandidateProfile(data) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}candidate-profiles`,
        data,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createCompanyUserProfile(data) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}candidate-profiles/company-users`,
        data,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // async createCandidateProfile(data, token) {
  //   try {
  //     const response = await BaseApi.postWithToken(
  //       `${APIURL.API_URL}candidate-profiles`,
  //       data,
  //       token
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async updateCandidateProfile(id, data, token) {
    return BaseApi.putWithToken(
      `${APIURL.API_URL}candidate-profiles/${id}`,
      data,
      token
    );
  }

  async deleteCandidateProfile(id, token) {
    return BaseApi.deleteWithToken(
      `${APIURL.API_URL}candidate-profiles/${id}`,
      token
    );
  }

  // async createCompanyUserProfile(data, token) {
  //   try {
  //     const response = await BaseApi.postWithToken(
  //       `${APIURL.API_URL}candidate-profiles/company-users`,
  //       data,
  //       token
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  postWithToken(url, data, token) {
    return BaseApi.postWithToken(url, data, token);
  }

  async initiateGpt4oConversation(audioData) {
    const token = LocalStorage.getAccessToken();
    return this.postWithToken(
      `${APIURL.API_URL}gpt4o-conversation`,
      audioData,
      token
    );
  }
  // async getJobs(token) {
  //   const response = await BaseApi.getWithToken(`${APIURL.API_URL}jobs`, token);
  //   return response;
  //   console.log("CommonAPI response", response);
  // }

  async getJobs(token, companyId) {
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}jobs?companyId=${companyId}`,
      token
    );
    return response;
  }
  // async getJobs(token, searchQuery) {
  //   if (!token) {
  //     throw new Error("No token provided");
  //   }

  //   try {
  //     const response = await BaseApi.getWithToken(
  //       `${APIURL.API_URL}jobs?query=${searchQuery}`,
  //       token
  //     );
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async createJob(data) {
    try {
      const token = LocalStorage.getAccessToken();
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}jobs`,
        data,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateJob(jobId, updateData) {
    try {
      const token = LocalStorage.getAccessToken();
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}jobs/${jobId}`,
        updateData,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addCandidates(jobId, updateData, token) {
    // const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}jobs/${jobId}/candidates`,
        updateData,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async parseResume(formData) {
    try {
      const token = LocalStorage.getAccessToken();
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}resume-parser/upload`,
        formData,
        token,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        }
      );
      return response.data;
    } catch (error) {
      // console.error('Error parsing resumes:', error);
      throw error;
    }
  }

  async getJobById(jobId) {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}jobs/${jobId}`,
      token
    );
    console.log("response job", response.data);
  }

  async getResumesByJobId(jobId) {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}jobs/${jobId}/resumes`,
      token
    );
    return response.data;
  }

  async getCountries() {
    try {
      const response = await axios.get(`${APIURL.API_URL}country/countries`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getStates(countryCode) {
    try {
      const response = await axios.get(
        `${APIURL.API_URL}country/states/${countryCode}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async generateChatResponse(
    prompt,
    job,
    resume,
    conversationHistory = [],
    maxTokens = 300,
    maxQuestions = 5
  ) {
    try {
      const response = await axios.post(`${APIURL.API_URL}openai/generate`, {
        prompt,
        job,
        resume,
        conversationHistory,
        maxTokens,
        maxQuestions,
      });
      return response.data;
    } catch (error) {
      console.error("Error generating chat response:", error);
      throw error;
    }
  }

  async initializeInterview(jobDescription, candidateName) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}openai/initialize`,
        { jobDescription, candidateName },
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error initializing interview:", error);
      throw error;
    }
  }

  // Method to generate a response during the interview
  // async generateChatResponse(prompt, jobDescription, resume) {
  //   try {
  //     const token = LocalStorage.getAccessToken();
  //     const response = await axios.post(
  //       `${APIURL.API_URL}openai/generate`,
  //       { prompt, jobDescription, resume },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error generating chat response:', error);
  //     throw error;
  //   }
  // }

  // Method to get the current status of the interview
  async getInterviewStatus() {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}openai/status`,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching interview status:", error);
      throw error;
    }
  }

  // Method to end the interview session
  async endInterview() {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}openai/end`,
        {},
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error ending interview:", error);
      throw error;
    }
  }

  async getInterviewDetails({ uniqueKey }) {
    try {
      const response = await axios.post(
        `${APIURL.API_URL}/interview/get-interview-details`,
        { uniqueKey }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching interview details:", error);
      throw error;
    }
  }

  async scheduleInterview(interviewDetails) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}interview/schedule`,
        interviewDetails,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error scheduling interview:", error);
      throw error;
    }
  }

  async updateResumeStatus(resumeId, status, token) {
    try {
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}interview/parsed-resumes/update-status`,
        { resumeId, status },
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getCompanyWithRoleCounts(companyId, token) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}companies/find/${companyId}/with-role-counts`,
        token
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getDashboardData() {
    const token = LocalStorage.getAccessToken(); // Retrieve the access token from local storage
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}dashboard-data`,
        token
      );
      return response.data; // Return the dashboard data
    } catch (error) {
      throw error; // Handle errors as needed
    }
  }

  async getJobsByUser() {
    const token = LocalStorage.getAccessToken(); // Retrieve the access token from local storage
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}jobs/user-jobs`,
        token
      );
      return response.data; // Return the dashboard data
    } catch (error) {
      throw error; // Handle errors as needed
    }
  }

  // async getJobsByUser(token) {
  //   const response = await BaseApi.getWithToken(`${APIURL.API_URL}user-jobs`, token);
  //   return response.data;
  // }

  async getParsedResumesByUser(token) {
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}user-resumes`,
      token
    );
    return response.data;
  }

  async getCandidateProfileDetails(candidateProfileId) {
    try {
      const token = LocalStorage.getAccessToken(); // Retrieve the access token
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}candidateProfile/${candidateProfileId}/details`,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching candidate profile details:", error);
      throw error;
    }
  }

  async getTokensUsage() {
    try {
      const token = LocalStorage.getAccessToken(); // Retrieve the access token
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}companies/getTokensUsage`,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching candidate profile details:", error);
      throw error;
    }
  }

  async generateResponseWithAudio(data) {
    const token = LocalStorage.getAccessToken();
    return BaseApi.postWithToken(
      `${APIURL.API_URL}openai/generate-with-audio`,
      data,
      token
    );
  }

  // Function to register an employer
  async registerEmployer(data) {
    try {
      const response = await axios.post(
        `${APIURL.API_URL}employers/register`,
        data
      );
      if (response.status === 201) {
        LocalStorage.setAccessToken(response.data.token); // Store the token if registration is successful
      }
      return response;
    } catch (error) {
      console.error("Error during employer registration:", error);
      throw error;
    }
  }

  // Employer login API
  async loginEmployer(data) {
    try {
      const response = await axios.post(
        `${APIURL.API_URL}employers/login`,
        data
      ); // Assuming /employers/login is your login endpoint
      return response;
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  }

  async createJobDescription(data) {
    try {
      const response = await BaseApi.post(`${APIURL.API_URL}jobs/create`, data);
      return response;
    } catch (error) {
      console.error("Error creating job description:", error);
      throw error;
    }
  }
  async uploadResumes(data) {
    try {
      const response = await BaseApi.post(
        `${APIURL.API_URL}resumes/upload`,
        data
      );
      return response;
    } catch (error) {
      console.error("Error uploading resumes:", error);
      throw error;
    }
  }

  // Interviewee registration API
  async registerInterviewee(data) {
    try {
      const response = await axios.post(
        `${APIURL.API_URL}interviewees/register`,
        data
      );
      if (response.status === 201) {
        LocalStorage.setAccessToken(response.data.token); // Store the token if registration is successful
      }
      return response;
    } catch (error) {
      console.error("Error during interviewee registration:", error);
      throw error;
    }
  }

  // Interviewee login API
  async loginInterviewee(data) {
    try {
      const response = await axios.post(
        `${APIURL.API_URL}interviewees/login`,
        data
      );
      return response;
    } catch (error) {
      console.error("Error during interviewee login:", error);
      throw error;
    }
  }

  async uploadIntervieweeFiles(formData, token) {
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}interviewees/upload-files`,
        formData,
        token,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Function to initialize the chatbot interview
  //  async initializeChatbotInterview(data) {
  //   try {
  //     const token = LocalStorage.getAccessToken(); // Get the token from local storage
  //     const response = await BaseApi.postWithToken(
  //       `${APIURL.API_URL}interviewees/initialize-interview`,
  //       data,
  //       token
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error initializing chatbot interview:", error);
  //     throw error;
  //   }
  // }

  // // Function to continue the chatbot interview
  // async continueChatbotInterview(data) {
  //   try {
  //     const token = LocalStorage.getAccessToken(); // Get the token from local storage
  //     const response = await BaseApi.postWithToken(
  //       `${APIURL.API_URL}interviewees/continue-interview`,
  //       data,
  //       token
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error continuing chatbot interview:", error);
  //     throw error;
  //   }
  // }

  // Method to initialize the chatbot interview
  async initializeChatbotInterview(data) {
    return BaseApi.postWithToken(
      `${APIURL.API_URL}interviewee/initialize-chatbot-interview`,
      data
    );
  }

  // Method to continue the chatbot interview
  async continueChatbotInterview(data) {
    return BaseApi.postWithToken(
      `${APIURL.API_URL}interviewee/continue-chatbot-interview`,
      data
    );
  }

  async saveReportCard(reportData) {
    try {
      const token = LocalStorage.getAccessToken();
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}report-card/save`,
        reportData,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error saving report card:", error);
      throw error;
    }
  }

  async getReportCardByCandidateProfileId(candidateProfileId, token) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}report-card/candidate/${candidateProfileId}`,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching report card:", error);
      throw error;
    }
  }

  // /:assessmentId/report

  async getLevel1ReportCard(assessmentId, token) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}individual/${assessmentId}/report`,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching report card:", error);
      throw error;
    }
  }

  // async generateChatResponse(answers) {
  //   try {
  //     const response = await BaseApi.postWithData(
  //       `${APIURL.API_URL}jobs/chatbotjob`,
  //       answers
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error generating chat response:", error);
  //     throw error;
  //   }
  // }

  async generateJobDescriptionWithAnswers(answers) {
    try {
      const response = await BaseApi.postWithData(
        `${APIURL.API_URL}jobs/chatbotjob`, // Ensure this endpoint matches your backend route
        answers
      );
      return response.data;
    } catch (error) {
      console.error("Error generating job description:", error);
      throw error;
    }
  }

  async saveJobData(jobData) {
    try {
      const response = await BaseApi.postWithData(
        `${APIURL.API_URL}jobs/create`, // This should match your backend route
        jobData
      );
      return response.data;
    } catch (error) {
      console.error("Error saving job data:", error);
      throw error;
    }
  }

  async parseJobDescriptionFromFile(formData) {
    const token = LocalStorage.getAccessToken(); // Retrieve the access token from local storage
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}jobs/parse-job-description`,
        formData,
        token,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error parsing job description:", error);
      throw error;
    }
  }

  async getInterviewDetails(token) {
    try {
      const response = await axios.get(`${APIURL.API_URL}interview/${token}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching interview details from backend:", error);
      throw error;
    }
  }

  // async verifyInterviewToken(token) {
  //   try {
  //     const response = await axios.get(`${APIURL.API_URL}interview/${token}`);
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error verifying interview token:", error);
  //     throw error;
  //   }
  // }

  async verifyInterviewKey(uniqueKey) {
    try {
      const response = await axios.post(
        `${APIURL.API_URL}interview/validate-key`,
        { key: uniqueKey }
      );
      console.log("Response from validate-key API:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error verifying interview key:", error);
      throw error;
    }
  }

  async updateEmailAndPassword(data) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.putWithToken(
        `${APIURL.API_URL}individual/update-password`,
        data,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error updating email and password:", error);
      throw error;
    }
  }

  async getEmailVerificationStatus(token) {
    try {
      const response = await axios.get(
        `${APIURL.API_URL}users/email-verification-status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error checking email verification status:",
        error.response?.data || error.message
      );
      throw error;
    }
  }
  async generateTOTPQRCode() {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}individual/generate-totp-qrcode`,
        {},
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error generating TOTP QR code:", error);
      throw error;
    }
  }

  async verifyTOTPToken(tokenData) {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}individual/verify-totp-token`,
        tokenData,
        token
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying TOTP token:", error);
      throw error;
    }
  }
  async getRecentLogins(token) {
    try {
      const response = await BaseApi.getWithToken(
        `${APIURL.API_URL}auth/recent-logins`,
        token
      );
      return response.data.recentLogins;
    } catch (error) {
      console.error("Error fetching recent logins:", error);
      throw error;
    }
  }

  async uploadIndividualResume(formData) {
    try {
      const token = LocalStorage.getAccessToken();
      const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}individual/upload-resume`,
        formData,
        token,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading resume:", error);
      throw error;
    }
  }

  async getResumeStatus() {
    try {
        const token = LocalStorage.getAccessToken();
        const response = await BaseApi.getWithToken(
            `${APIURL.API_URL}individual/resume-status`,
            token
        );
        return response.data; // Assuming the API returns an object with `hasResume` and `resumes` fields
    } catch (error) {
        console.error("Error fetching resume status:", error);
        throw error;
    }
}

async deleteResume(resumeId) {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.postWithToken(
        `${APIURL.API_URL}individual/resume/${resumeId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (response.ok) {
      console.log("Resume deleted:", data);
    } else {
      console.error("Error deleting resume:", data.message);
    }
  } catch (error) {
    console.error("Error:", error);
  }
}


async getPrimaryResume() {
  const response = await this.getResumeStatus();
  if (response.resumes && response.resumes.length > 0) {
      return response.resumes.find((resume) => resume.isPrimary) || response.resumes[0];
  }
  return null;
}

// Fetch Recommendations
// async getRecommendations(resumeId) {
//   try {
//       const token = LocalStorage.getAccessToken();
//       const response = await BaseApi.postWithToken(
//           `${APIURL.API_URL}role-parser/upload`,
//           { resumeId },
//           token
//       );
//       return response.data;
//   } catch (error) {
//       console.error('Error fetching recommendations:', error);
//       throw error;
//   }
// }

async recommendCertifications(formData) {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.postWithToken(
      `${APIURL.API_URL}recommendations//parse-primary-resume`,
      formData,
      token
    );
    return response.data;
  } catch (error) {
    console.error("Error generating recommendations:", error);
    throw error;
  }
}


async getRecommendations() {
  try {
    const token = LocalStorage.getAccessToken(); // Fetch the token from local storage
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}recommendations/get-recommendations`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recommendations:", error);
    throw error;
  }
}

async parsePrimaryResume(resumeId) {
  const token = LocalStorage.getAccessToken();
  const response = await axios.post(
    `${APIURL.API_URL}recommendations/parse-primary-resume`,
    { resumeId }, // Ensure this sends { resumeId: '...' }
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

async startAssessment(data) {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.postWithToken(
      `${APIURL.API_URL}assessments/start`,
      data,
      token
    );
    return response.data;
  } catch (error) {
    console.error("Error starting assessment:", error);
    throw error;
  }
}


async submitAssessment(assessmentId, answers, level = "level1") {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.postWithToken(
      `${APIURL.API_URL}assessments/${assessmentId}/${level}/submit`,
      { answers },
      token
    );
    return response.data;
  } catch (error) {
    console.error(`Error submitting ${level} assessment:`, error);
    throw error;
  }
}


async getAssessmentReport(assessmentId) {
  try {
    const token = localStorage.getItem("accessToken"); // Ensure token retrieval is correct
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}assessments/${assessmentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Properly pass the token
        },
      }
    );
    return response.data; // Ensure this matches the backend response structure
  } catch (error) {
    console.error("Error fetching assessment report:", error);
    throw error; // Ensure the error is propagated to handle it in the component
  }
}


async getAssessmentList () {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}assessments/reports`, token
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching assessments:", error);
    throw error;
  }
};



async fetchFinalReport(assessmentId) {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}assessments/${assessmentId}/final-report`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return the report data
  } catch (error) {
    console.error("Error fetching final report:", error);
    throw error; // Propagate the error for the caller to handle
  }
}

async retryAssessment(assessmentId) {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}assessments/${assessmentId}/retry-assessment`,token);
    return response.data; // Return the report data
  } catch (error) {
    console.error("Error retring the assessment", error);
    throw error; // Propagate the error for the caller to handle
  }
}

async getAssessmentInterviewDetails(assessmentId) {
  try {
    const token = LocalStorage.getAccessToken(); // Get the token from localStorage
    if (!token) {
      throw new Error('No access token found.');
    }

    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}assessments/interview/${assessmentId}`, // Construct the endpoint
      token
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching interview assessment:', error);
    throw error; // Propagate the error to be handled by the component
  }
}

async sendEmailOtp(email){
  const response = await axios.post(
    `${APIURL.API_URL}auth/sendEmailOtp`,
    {email}
  );
  if(response.status==200){
    return response.data;
  }else{
    console.error('Error sending OTP');
  }
}

async verifyEmailOtp(email,otp){
  const response = await axios.post(
    `${APIURL.API_URL}auth/verifyEmailOtp`,
    {email,otp}
  );
  if(response.status==200){
    return response.data;
  }else{
    console.error('Error verifying OTP');
  }
}

async getSkills() {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(`${APIURL.API_URL}individual/get-skills`,token);
    return response.data;
  } catch (error) {
    console.error("Error fetching skills:", error);
    throw error;
  }
}


async getEducation() {
  const token = LocalStorage.getAccessToken();
  return await BaseApi.getWithToken(`${APIURL.API_URL}individual/get-education`, token);
}

async updateEducation(educationData) {
  const token = LocalStorage.getAccessToken();
  return await BaseApi.putWithToken(`${APIURL.API_URL}individual/update-education`, educationData, token);
}

async deleteEducation(educationId) {
  const token = LocalStorage.getAccessToken();
  return await BaseApi.deleteWithToken(`${APIURL.API_URL}individual/education/${educationId}`, token);
}

async sendEmailVerificationCode() {
  try {
    const token = LocalStorage.getAccessToken();
      const response = await BaseApi.postWithToken(
          `${APIURL.API_URL}individual/send-email-verification-code`,
          {},
          token
      );
      return response.data;
  } catch (error) {
      console.error('Error sending email verification code:', error);
      throw error;
  }
}

async verifyEmailToken(otp) {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.postWithToken(
      `${APIURL.API_URL}individual/verify-email-token`,
      otp, // Send the token as a raw string
      token
    );
    return response.data;
  } catch (error) {
    console.error('Error verifying email token:', error);
    throw error;
  }
}


async getProfileCompletion() {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.getWithToken(
      `${APIURL.API_URL}individual/profile-completion`,
      token
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching profile completion:", error);
    throw error;
  }
}


async updateAccountInfo(data) {
  try {
    const token = LocalStorage.getAccessToken();
    const response = await BaseApi.putWithToken(
      `${APIURL.API_URL}individual/update-account-info`, // Ensure this matches your backend route
      data,
      token
    );
    return response.data;
  } catch (error) {
    console.error("Error updating account information:", error);
    throw error;
  }
}


  // Other API methods...
}

export default new CommonApi();
