import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const CertificationsList = ({ roleCertifications, role, onSelectAssessment }) => {
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [refreshDisabled, setRefreshDisabled] = useState(false); // For disabling the refresh button temporarily

  const getItemsToDisplay = () => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return roleCertifications.slice(start, end);
  };

  const handleNext = () => {
    if (refreshDisabled) return; // Ignore clicks when disabled
    setRefreshDisabled(true); // Disable button temporarily

    setTimeout(() => {
      setRefreshDisabled(false); // Re-enable after 1 second
    }, 1000);

    const nextPage = currentPage + 1;
    if (nextPage * itemsPerPage >= roleCertifications.length) {
      setOpenSnackbar(true);
      setCurrentPage(0); // Reset to the first page when at the end
    } else {
      setCurrentPage(nextPage); // Go to the next page
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {roleCertifications.length > 0 && (
        <Box sx={{ marginBottom: 6 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              marginTop: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: "inline",
                fontWeight: "bold",
                textAlign: "center",
                flex: "none",
                marginRight: 2,
              }}
            >
              Recommended {role}-Based Certifications
            </Typography>
            {/* Tooltip added around the refresh button */}
            <Tooltip title="Refresh the list of certifications" arrow>
              <Button
                variant="text"
                color="primary"
                sx={{
                  margin: 0,
                  padding: 0.5,
                  minWidth: "auto",
                  width: "auto",
                  borderRadius: "100%",
                  marginTop: "5px",
                }}
                onClick={handleNext}
                disabled={refreshDisabled} // Disable button when refreshing
              >
                <RefreshIcon />
              </Button>
            </Tooltip>
          </Box>

          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            {getItemsToDisplay().map((cert) => (
              <Grid item xs={12} sm={4} key={cert.role}>
                <Box
                  sx={{
                    padding: 3,
                    border: "1px solid #e0e0e0",
                    borderRadius: 2,
                    textAlign: "center",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", marginBottom: 1 }}
                  >
                    {cert.role}
                  </Typography>
                  <Typography variant="body1">{cert.certification}</Typography>
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: 2,
                      backgroundColor: "#ff2600",
                      color: "white",
                      "&:hover": { backgroundColor: "#e02400" },
                    }}
                    onClick={() => onSelectAssessment(cert)}
                  >
                    Start Assessment
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              No further recommendations. Returning to the start.
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
};

export default CertificationsList;
