import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CommonApi from '../../../apis/CommonApi';

const Employment = () => {
  const [open, setOpen] = useState(false);
  const [employmentData, setEmploymentData] = useState([]);
  const [formValues, setFormValues] = useState({
    isCurrent: "yes",
    employmentType: "full-time",
    totalExperience: "",
    months: "",
    companyName: "",
    jobTitle: "",
    joiningDate: new Date(),
    joiningMonth: "January",
    salary: "",
    skillsUsed: "",
    jobProfile: "",
    noticePeriod: "",
  });
  const [error, setError] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFormValues({
      isCurrent: "yes",
      employmentType: "full-time",
      totalExperience: "",
      months: "",
      companyName: "",
      jobTitle: "",
      joiningDate: new Date(),
      joiningMonth: "January",
      salary: "",
      skillsUsed: "",
      jobProfile: "",
      noticePeriod: "",
    });
    setOpen(false);
  };

  const fetchEmploymentData = async () => {
    try {
      const data = await CommonApi.getEmployment();
      setEmploymentData(data.employment || []);
    } catch (error) {
      console.error("Error fetching employment data:", error);
    }
  };

  useEffect(() => {
    fetchEmploymentData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormValues({ ...formValues, joiningDate: date });
  };

  const handleSaveEmployment = async () => {
    try {
      setError(null);
      await CommonApi.updateEmployment({
        ...formValues,
        joiningDate: formValues.joiningDate.toISOString().split("T")[0],
      });
      fetchEmploymentData();
      handleClose();
    } catch (error) {
      console.error("Error updating employment:", error);
      setError("Failed to update employment. Please try again.");
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Employment</Typography>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          color="primary"
          variant="contained"
          onClick={handleOpen}
          size="small"
        >
          Add Employment
        </Button>
      </Box>

      {employmentData.length === 0 ? (
        <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
          No employment data available. Add your first employment record!
        </Typography>
      ) : (
        employmentData.map((employment, index) => (
          <Card key={index} sx={{ mb: 2, boxShadow: 3, borderRadius: 2 }}>
            <CardHeader
              title={employment.jobTitle}
              subheader={employment.companyName}
              action={
                <IconButton onClick={() => setOpen(true)} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                {employment.employmentType} | {new Date(employment.joiningDate).toLocaleDateString("en-US", { year: "numeric", month: "short" })} to {employment.isCurrent ? "Present" : "End Date"}
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body2" color="textPrimary">
                <strong>Salary:</strong> {employment.salary || "N/A"}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                <strong>Skills Used:</strong> {employment.skillsUsed || "N/A"}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                <strong>Job Profile:</strong> {employment.jobProfile || "N/A"}
              </Typography>
            </CardContent>
          </Card>
        ))
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Add/Edit Employment
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Fill out the details of your employment record.
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Joining Date"
              value={formValues.joiningDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="Company name"
            variant="outlined"
            name="companyName"
            value={formValues.companyName}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Job title"
            variant="outlined"
            name="jobTitle"
            value={formValues.jobTitle}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <Grid container spacing={2} mb={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Total Experience (Years)"
                variant="outlined"
                name="totalExperience"
                value={formValues.totalExperience}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Months"
                variant="outlined"
                name="months"
                value={formValues.months}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            label="Joining Month"
            variant="outlined"
            name="joiningMonth"
            value={formValues.joiningMonth}
            onChange={handleChange}
            select
            sx={{ mb: 2 }}
          >
            {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((month) => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Skills Used"
            variant="outlined"
            name="skillsUsed"
            value={formValues.skillsUsed}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Job Profile"
            variant="outlined"
            name="jobProfile"
            value={formValues.jobProfile}
            onChange={handleChange}
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button onClick={handleClose} color="secondary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleSaveEmployment} color="primary" variant="contained">
              Save
            </Button>
          </Box>
          {error && (
            <Typography color="error" variant="body2" mt={2}>
              {error}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Employment;
