import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  Paper,
  Button,
  Stack,
} from "@mui/material";
import IndividualHeader from "../IndividualHeader";

const ReportCardPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  // Destructure the state values
  const { assessmentId, score = 0, firstName = "", lastName = "" } = state || {};

  const handleRetakeTest = () => {
    navigate("/assessment", { state: { assessmentId } });
  };

  const handleLevel2Navigation = () => {
    if (score >= 50) {
      navigate("/level-2", { state: { assessmentId, level1Score: score } });
    } else {
      alert("You need a score of at least 50 in level 1 to proceed to Level 2.");
    }
  };

  const navigateToAssessment = () => {
    navigate("/assessment-main");
  };

  return (
    <>
      <IndividualHeader />
      <Container maxWidth="md" sx={{ mt: 8 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "12px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Level 1 Assessment Report
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong>Name:</strong> {firstName} {lastName}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong>Score:</strong> {score}/70
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: 4,
                color: score >= 50 ? "green" : "red",
              }}
            >
              {score >= 50
                ? "Congratulations! You Passed."
                : "You did not qualify. Better luck next time!"}
            </Typography>
          </Box>

          {/* Buttons Section */}
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                maxWidth: "400px",
                margin: "0 auto",
                "& button": { borderRadius: "12px" },
              }}
            >
              {/* Retake Button */}
              {score < 50?
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#ff2600",
                  color: "white",
                  "&:hover": { backgroundColor: "#e52400" },
                }}
                onClick={handleRetakeTest}
              >
                Retake Test
              </Button>
              :(<Button
                variant="contained"
                sx={{
                  backgroundColor: score >= 50 ? "green" : "grey",
                  color: "white",
                  "&:hover": {
                    backgroundColor: score >= 50 ? "#006400" : "grey",
                  },
                }}
                onClick={handleLevel2Navigation}
                disabled={score < 50}
              >
                Attempt Level 2 Assessment
              </Button>)
                }
              {/* Back to Assessment Button */}
              {/* <Button
                variant="outlined"
                sx={{
                  borderColor: "#1976d2",
                  color: "#1976d2",
                  "&:hover": {
                    backgroundColor: "#1976d2",
                    color: "white",
                    borderColor: "#1976d2",
                  },
                }}
                onClick={navigateToAssessment}
              >
                Back to Assessment
              </Button> */}
            </Stack>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default ReportCardPage;
