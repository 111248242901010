import React, { createContext, useState, useEffect } from "react";
import CommonApi from "./apis/CommonApi";

export const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  // Global state for resume management
  const [resumeInfo, setResumeInfo] = useState(null);

  // Function to fetch the primary resume
  const fetchPrimaryResume = async () => {
    try {
      const response = await CommonApi.getResumeStatus();
      if (response && response.resumes) {
        const primaryResume = response.resumes.find((resume) => resume.primary === true);
        setResumeInfo(primaryResume || null);
      } else {
        setResumeInfo(null);
      }
    } catch (error) {
      console.error("Error fetching primary resume:", error);
      setResumeInfo(null);
    }
  };

  // Fetch primary resume when the context initializes
  useEffect(() => {
    fetchPrimaryResume();
  }, []);

  return (
    <MyContext.Provider value={{ resumeInfo, setResumeInfo, fetchPrimaryResume }}>
      {children}
    </MyContext.Provider>
  );
};
