import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Paper,
} from "@mui/material";
import IndividualHeader from "../IndividualHeader";
import { useNavigate, useLocation } from "react-router-dom";
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from "../../../utils/LocalStorage";

const AssessmentConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the state passed from the previous page (AssessmentStart)
  const { firstName, lastName, assessment } = location.state || {};

  const [experience, setExperience] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let token = LocalStorage.getAccessToken();
    if(!token){
      navigate('/')
    }

  },[])

  useEffect(() => {
    if (!firstName || !lastName || !assessment) {
      navigate("/assessment-start"); // Redirect to start page if no data
    }
  }, [firstName, lastName, assessment, navigate]);

  const handleStartTest = async () => {
    if (!experience || !agreedToTerms || !agreedToPrivacy) {
      alert("Please fill out all the required fields.");
      return;
    }

    setLoading(true);

    try {
      const customName = assessment.role
        ? assessment.role + " Certification"
        : assessment.skill
        ? assessment.skill + " Specialist"
        : null;
      const payload = {
        firstName,
        lastName,
        experienceLevel: experience,
        customName,
      };
      const response = await CommonApi.startAssessment(payload);
      if (response) {
        navigate("/assessment-environment-setup", {
          state: { ...response.data },
        });
      }
    } catch (error) {
      console.error("Error starting the assessment:", error);
      alert("Failed to save assessment details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IndividualHeader />
      <Container maxWidth="md" sx={{ mt: 10, mb: 6 }}>
        <Paper
          elevation={4}
          sx={{
            borderRadius: 4,
            p: 4,
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
          }}
        >
          {/* Welcome Section */}
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "#333",
                mb: 2,
              }}
            >
              Welcome to the {assessment ? assessment.role : "Selected Role"} Test
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#6c757d",
                fontSize: "1.1rem",
                fontWeight: "500",
                mb: 2,
              }}
            >
              <strong>Name:</strong> {firstName} {lastName}
            </Typography>
          </Box>

          {/* Assessment Details Section */}
          <Box
            sx={{
              mb: 6,
              p: 3,
              backgroundColor: "#f4f6fa",
              borderRadius: 3,
              boxShadow: "inset 0px 3px 6px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Selected Assessment Details
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 1, fontSize: "1.05rem", fontWeight: "500" }}
            >
              <strong>Role:</strong>{" "}
              {assessment?.role ? assessment.role : assessment?.skill || "N/A"}
            </Typography>
          </Box>

          {/* Confirmation Form Section */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Confirmation Form
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 3,
                color: "#6c757d",
                maxWidth: 600,
                mx: "auto",
                fontSize: "1rem",
              }}
            >
              Please confirm your details and complete the form below to start
              the assessment.
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: 2, fontWeight: "500", color: "#333" }}
            >
              <strong>Logged in as:</strong> {firstName} {lastName}
            </Typography>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Work Experience (in years)</InputLabel>
              <Select
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                label="Work Experience (in years)"
                sx={{
                  borderRadius: 2,
                  backgroundColor: "#f4f6fa",
                }}
              >
                <MenuItem value="1">{"<1"}</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">{">5"}</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                  sx={{ color: "#ff2600" }}
                />
              }
              label={
                <Typography sx={{ fontSize: "0.95rem" }}>
                  I agree not to copy or refer code from any source, including
                  colleagues, nor will I access any websites or AI tools to
                  assist me with my answers.
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreedToPrivacy}
                  onChange={(e) => setAgreedToPrivacy(e.target.checked)}
                  sx={{ color: "#ff2600" }}
                />
              }
              label={
                <Typography sx={{ fontSize: "0.95rem" }}>
                  I agree to Optima AI's{" "}
                  <a
                    href="/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#ff2600", textDecoration: "underline" }}
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href="/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#ff2600", textDecoration: "underline" }}
                  >
                    Privacy Policy
                  </a>.
                </Typography>
              }
            />

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#ff2600",
                color: "#fff",
                mt: 3,
                borderRadius: "12px",
                width: "100%",
                textTransform: "uppercase",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#e02400",
                },
              }}
              disabled={!experience || !agreedToTerms || !agreedToPrivacy}
              onClick={handleStartTest}
            >
              {loading ? <CircularProgress size={20} /> : "Start Assessment"}
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default AssessmentConfirmation;
