import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CommonApi from "../../../apis/CommonApi";

const Education = () => {
  const [open, setOpen] = useState(false);
  const [educationData, setEducationData] = useState({
    educationLevel: "",
    university: "",
    course: "",
    specialization: "",
    courseType: "full-time",
    startingYear: "",
    endingYear: "",
    gradingSystem: "",
  });
  const [educationList, setEducationList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEducationData();
  }, []);

  const fetchEducationData = async () => {
    try {
        const response = await CommonApi.getEducation(); // Ensure this API returns education data
        const educationList = response.data?.education || [];
        setEducationList(educationList);
    } catch (error) {
        console.error("Error fetching education data:", error);
    }
};


  const generateYearOptions = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleOpen = () => {
    setEducationData({
      educationLevel: "",
      university: "",
      course: "",
      specialization: "",
      courseType: "full-time",
      startingYear: "",
      endingYear: "",
      gradingSystem: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEducationData({ ...educationData, [name]: value });
  };

  const handleSaveEducation = async () => {
    try {
      setError(null);
      const response = await CommonApi.updateEducation(educationData);
      setEducationList(response.education);
      handleClose();
    } catch (error) {
      console.error("Error updating education:", error);
      setError("Failed to update education. Please try again.");
    }
  };

  // Generate year options dynamically
  const yearOptions = generateYearOptions(1980, new Date().getFullYear());

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Education
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleOpen}>
          Add Education
        </Button>
      </Box>
      <Box mb={2}>
        {educationList && educationList.length > 0 ? (
          educationList.map((education, index) => (
            <Box key={index} mb={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {education.educationLevel}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {education.university}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {education.startingYear} - {education.endingYear}
              </Typography>
              <IconButton size="small" color="primary">
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No education data available.
          </Typography>
        )}
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Education
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Education Level"
            name="educationLevel"
            select
            value={educationData.educationLevel}
            onChange={handleChange}
            sx={{ mb: 2 }}
          >
            <MenuItem value="High School">High School</MenuItem>
            <MenuItem value="Undergraduate">Undergraduate</MenuItem>
            <MenuItem value="Graduate">Graduate</MenuItem>
            <MenuItem value="Doctorate">Doctorate</MenuItem>
          </TextField>
          <TextField
            fullWidth
            label="University"
            name="university"
            value={educationData.university}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Course"
            name="course"
            select
            value={educationData.course}
            onChange={handleChange}
            sx={{ mb: 2 }}
          >
            <MenuItem value="Bachelor of Science">Bachelor of Science</MenuItem>
            <MenuItem value="Bachelor of Arts">Bachelor of Arts</MenuItem>
            <MenuItem value="Master of Science">Master of Science</MenuItem>
          </TextField>
          <TextField
            fullWidth
            label="Specialization"
            name="specialization"
            select
            value={educationData.specialization}
            onChange={handleChange}
            sx={{ mb: 2 }}
          >
            <MenuItem value="Computer Science">Computer Science</MenuItem>
            <MenuItem value="Mathematics">Mathematics</MenuItem>
            <MenuItem value="Physics">Physics</MenuItem>
          </TextField>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Starting Year"
                name="startingYear"
                select
                value={educationData.startingYear}
                onChange={handleChange}
              >
                {yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Ending Year"
                name="endingYear"
                select
                value={educationData.endingYear}
                onChange={handleChange}
              >
                {yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            label="Grading System"
            name="gradingSystem"
            select
            value={educationData.gradingSystem}
            onChange={handleChange}
            sx={{ mb: 2 }}
          >
            <MenuItem value="Percentage">Percentage</MenuItem>
            <MenuItem value="CGPA">CGPA</MenuItem>
            <MenuItem value="GPA">GPA</MenuItem>
          </TextField>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveEducation}
            >
              Save
            </Button>
          </Box>
          {error && (
            <Typography color="error" variant="body2" mt={2}>
              {error}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Education;
