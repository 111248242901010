import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Button,
  Skeleton,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import IndividualHeader from "../IndividualHeader";
import { useNavigate } from "react-router-dom";
import CommonApi from "../../../apis/CommonApi";
import CertificationsList from "./roleCertificationsList";
import UpdateResume from "../settings/UpdateResume";
import { MyContext } from "../../../Context";
import { useMediaQuery, useTheme } from "@mui/material";
import LocalStorage from "../../../utils/LocalStorage";

const AssessmentMain = () => {
  const [mainResume, setMainResume] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [roleCertifications, setRoleCertifications] = useState([]);
  const [skillCertifications, setSkillCertifications] = useState([]);
  const [openModal, setOpenModal] = useState(false); // Modal state for desktop check
  const [profileCompletion, setProfileCompletion] = useState(0); // Track profile completion
  const [openProfileModal, setOpenProfileModal] = useState(false); // Modal for profile completion
  const navigate = useNavigate();
  const { resumeInfo } = useContext(MyContext);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // 900px and above

  useEffect(()=>{
    let token = LocalStorage.getAccessToken();
    if(!token){
      navigate('/')
    }

  },[])

  useEffect(() => {
    const fetchProfileCompletion = async () => {
      try {
        const response = await CommonApi.getProfileCompletion();
        setProfileCompletion(response.profileCompletion);
      } catch (err) {
        setError("Failed to fetch profile completion. Please try again.");
      }
    };

    const fetchPrimaryResume = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await CommonApi.getResumeStatus();
        if (response && response.resumes) {
          const primaryResume = response.resumes.find(
            (resume) => resume.primary === true
          );
          setMainResume(primaryResume || null);
          if (primaryResume) {
            fetchRecommendations(primaryResume._id);
          }
        }
      } catch (err) {
        setError("Failed to fetch primary resume. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfileCompletion();
    fetchPrimaryResume();
  }, [resumeInfo]);

  const fetchRecommendations = async (resumeId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await CommonApi.parsePrimaryResume(resumeId);
      const roleCerts = response?.recommendations?.roleBasedCertifications || [];
      const skillCerts =
        response?.recommendations?.skillBasedCertifications || [];
      setRoleCertifications(roleCerts);
      setSkillCertifications(skillCerts);

      localStorage.setItem("roleCertifications", JSON.stringify(roleCerts));
      localStorage.setItem("skillCertifications", JSON.stringify(skillCerts));
    } catch (err) {
      setError("Failed to fetch recommendations. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResumeUploaded = async (newResume) => {
    localStorage.removeItem("roleCertifications");
    localStorage.removeItem("skillCertifications");
    setRoleCertifications([]);
    setSkillCertifications([]);
    setMainResume(newResume);
    if (newResume && newResume._id) {
      await fetchRecommendations(newResume._id);
    }
  };

  const handleSelectAssessment = (certification) => {
    // Check if profile completion is less than 75%
    if (profileCompletion < 75) {
      setOpenProfileModal(true); // Open profile completion modal
      return;
    }

    // Check if not desktop
    if (!isDesktop) {
      setOpenModal(true); // Open desktop-only modal
      return;
    }

    // Navigate to the assessment page if conditions are met
    navigate("/assessment-start", { state: { assessment: certification } });
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close desktop-only modal
  };

  const handleCloseProfileModal = () => {
    setOpenProfileModal(false); // Close profile completion modal
  };

  return (
    <div>
      <IndividualHeader />
      <Container maxWidth="xl" sx={{ mt: 11, mb: 6 }}>
        <Box
          sx={{
            padding: 4,
            textAlign: "center",
            background: "linear-gradient(to right, #6a11cb, #2575fc)",
            borderRadius: 3,
            color: "#fff",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
              mb: 2,
            }}
          >
            Enhance Your Career with OptHyr Assessments Process
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "1.2rem", opacity: 0.9 }}
          >
            Discover tailored recommendations to boost your skills and excel in
            your career.
          </Typography>
        </Box>

        {mainResume ? (
          <Card
            sx={{
              padding: 4,
              borderRadius: 3,
              mb: 6,
              mt: 4,
              boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  color: "#333",
                }}
              >
                <Star sx={{ color: "#f9c74f", mr: 1 }} />
                Uploaded Resume
              </Typography>
              <Typography sx={{ color: "#6c757d", mb: 3 }}>
                Your primary resume details are displayed below.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                <strong>Name:</strong> {mainResume.name}
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                <strong>Uploaded On:</strong>{" "}
                {new Date(mainResume.uploadDate).toLocaleDateString()}
              </Typography>
              <UpdateResume
                hideDetails={true}
                onResumeUploaded={handleResumeUploaded}
              />
            </CardContent>
          </Card>
        ) : (
          <Card
            sx={{
              padding: 4,
              borderRadius: 3,
              mb: 6,
              mt: 4,
              textAlign: "center",
              background:
                "linear-gradient(135deg, rgba(255,255,255,1) 60%, rgba(235, 255, 234, 0.8) 100%)",
              border: "2px dashed #ccc",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#ff6b6b", mb: 2, fontWeight: "bold" }}
            >
              No resume available.
            </Typography>
            <UpdateResume
              hideDetails={true}
              onResumeUploaded={handleResumeUploaded}
            />
          </Card>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 4 }}>
            {error}
          </Alert>
        )}

        {loading ? (
          <Box sx={{ display: "grid", gap: 2 }}>
            <Skeleton variant="rectangular" height={120} />
            <Skeleton variant="rectangular" height={120} />
            <Skeleton variant="rectangular" height={120} />
          </Box>
        ) : (
          <>
            <CertificationsList
              roleCertifications={roleCertifications}
              role="Role"
              onSelectAssessment={handleSelectAssessment}
            />
            <CertificationsList
              roleCertifications={skillCertifications}
              role="Skill-Based"
              onSelectAssessment={handleSelectAssessment}
            />
          </>
        )}
      </Container>

      {/* Modal for desktop-only restriction */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{"Assessment can only be started on Desktop"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            The assessment feature is available only on desktop devices.
            Please switch to a desktop to start the assessment.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for profile completion restriction */}
      <Dialog open={openProfileModal} onClose={handleCloseProfileModal}>
        <DialogTitle>{"Complete Your Profile"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You need to complete at least 75% of your profile to start the assessment.
            Please update your profile in the account settings.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/account-settings")} color="primary">
            Go to Account Settings
          </Button>
          <Button onClick={handleCloseProfileModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssessmentMain;
