import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, CircularProgress, Box, Typography } from "@mui/material";
import CommonApi from "../../apis/CommonApi";

const VerifyAuth = () => {
    const [otpToken, setOtpToken] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleVerifyToken = async () => {
        setLoading(true);
        try {
            const response = await CommonApi.verifyTOTPToken({ token: otpToken });
            if (response.success) {
                alert("Verification successful.");
                navigate("/dashboard"); // Allow access to the app
            } else {
                alert("Invalid OTP. Please try again.");
            }
        } catch (error) {
            alert("Verification failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box textAlign="center" mt={5}>
            <Typography variant="h5" mb={3}>
                Verify Two-Step Authentication
            </Typography>
            <TextField
                label="Enter OTP"
                value={otpToken}
                onChange={(e) => setOtpToken(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleVerifyToken}
                disabled={loading}
            >
                {loading ? <CircularProgress size={24} /> : "Verify"}
            </Button>
        </Box>
    );
};

export default VerifyAuth;
