import React, { useState, useMemo, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import CommonApi from '../../apis/CommonApi';

// Sample Data with Updated Schema
const sampleData = [
  // {
  //   userName: "sudheer kumar kota",
  //   tokensUsed: {
  //     prompt: 177,
  //     completion: 182,
  //     total: 359,
  //     _id: "6756a8a453ca5b70965b9814"
  //   },
  //   index: "generating assessment questions for level 1",
  //   timestamp: "2024-12-09T08:21:56.268Z",
  //   __v: 0
  // },
  // {
  //   userName: "sudheer kumar kota",
  //   tokensUsed: {
  //     prompt: 177,
  //     completion: 193,
  //     total: 370,
  //     _id: "67594e199da30891da86264c"
  //   },
  //   index: "generating assessment questions for level 2",
  //   timestamp: "2024-12-09T08:21:56.268Z",
  //   __v: 0
  // },
  // {
  //   userName: "sudheer kumar kota",
  //   tokensUsed: {
  //     prompt: 177,
  //     completion: 184,
  //     total: 361,
  //     _id: "6759523e6b2a47a3b3d8a1c1"
  //   },
  //   index: "generating assessment questions for level 1",
  //   timestamp: "2024-12-11T08:50:06.635Z",
  //   __v: 0
  // },
  // {
  //   userName: "sudheer kumar kota",
  //   tokensUsed: {
  //     prompt: 178,
  //     completion: 179,
  //     total: 357,
  //     _id: "6759567526cb80a795db413e"
  //   },
  //   index: "generating assessment questions for level 1",
  //   timestamp: "2024-12-11T09:08:05.556Z",
  //   __v: 0
  // }
  // Add more entries if needed
];

const TokensUsageDashboard = () => {
  // State for sorting table
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('timestamp');
  const [selectedUser, setSelectedUser] = useState('');
  const [data, setData] = useState([]);

  // Fetch data from API on component mount
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await CommonApi.getTokensUsage();
        setData(response.data); // Adjust based on your API response structure
        console.log("Tokens Used are: ", response.data);
      } catch (error) {
        console.error("Error fetching tokens usage data:", error);
        // Optionally, set data to sampleData if API fails
        setData(sampleData);
      }
    }
    fetchData();
  }, []);

  // Extract unique userNames for the filter
  const uniqueUsers = useMemo(() => {
    const users = data.map(item => item.userName);
    return Array.from(new Set(users));
  }, [data]);

  // Filtered Data based on selected user
  const filteredData = useMemo(() => {
    if (!selectedUser) return data;
    return data.filter(item => item.userName === selectedUser);
  }, [selectedUser, data]);

  // Sort the filtered data
  const sortedData = useMemo(() => {
    const sorted = [...filteredData].sort((a, b) => {
      let aValue;
      let bValue;

      // Handle nested properties
      switch (orderBy) {
        case 'timestamp':
          aValue = new Date(a.timestamp);
          bValue = new Date(b.timestamp);
          break;
        case 'index':
          aValue = a.index.toLowerCase();
          bValue = b.index.toLowerCase();
          break;
        case 'prompt':
          aValue = a.tokensUsed.prompt;
          bValue = b.tokensUsed.prompt;
          break;
        case 'completion':
          aValue = a.tokensUsed.completion;
          bValue = b.tokensUsed.completion;
          break;
        case 'total':
          aValue = a.tokensUsed.total;
          bValue = b.tokensUsed.total;
          break;
        default:
          aValue = a[orderBy];
          bValue = b[orderBy];
      }

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return sorted;
  }, [filteredData, order, orderBy]);

  // Handle sorting request
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Create sort handler
  const createSortHandler = (property) => () => {
    handleRequestSort(property);
  };

  // Chart Data: Token Usage per Type (Prompt, Completion, Total)
  const chartData = sortedData.map((item) => ({
    index: item.index,
    prompt: item.tokensUsed.prompt,
    completion: item.tokensUsed.completion,
    total: item.tokensUsed.total,
  }));

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Tokens Usage Dashboard
      </Typography>

      <Grid container spacing={3} alignItems="center">
        {/* Filter Section */}
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="user-filter-label">Filter by User</InputLabel>
            <Select
              labelId="user-filter-label"
              id="user-filter"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label="Filter by User"
            >
              <MenuItem value="">
                <em>All Users</em>
              </MenuItem>
              {uniqueUsers.map((userName) => (
                <MenuItem key={userName} value={userName}>
                  {userName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        {/* Chart Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Tokens Used by Type
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="index" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="prompt" fill="#8884d8" />
              <Bar dataKey="completion" fill="#82ca9d" />
              <Bar dataKey="total" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Token Usage Logs
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* Timestamp Column */}
                  <TableCell sortDirection={orderBy === 'timestamp' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'timestamp'}
                      direction={orderBy === 'timestamp' ? order : 'asc'}
                      onClick={createSortHandler('timestamp')}
                    >
                      Timestamp
                    </TableSortLabel>
                  </TableCell>

                  {/* Index Column */}
                  <TableCell sortDirection={orderBy === 'index' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'index'}
                      direction={orderBy === 'index' ? order : 'asc'}
                      onClick={createSortHandler('index')}
                    >
                      Index
                    </TableSortLabel>
                  </TableCell>

                  {/* Input Tokens Column */}
                  <TableCell sortDirection={orderBy === 'prompt' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'prompt'}
                      direction={orderBy === 'prompt' ? order : 'asc'}
                      onClick={createSortHandler('prompt')}
                    >
                      Input Tokens
                    </TableSortLabel>
                  </TableCell>

                  {/* Output Tokens Column */}
                  <TableCell sortDirection={orderBy === 'completion' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'completion'}
                      direction={orderBy === 'completion' ? order : 'asc'}
                      onClick={createSortHandler('completion')}
                    >
                      Output Tokens
                    </TableSortLabel>
                  </TableCell>

                  {/* Total Tokens Column */}
                  <TableCell sortDirection={orderBy === 'total' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'total'}
                      direction={orderBy === 'total' ? order : 'asc'}
                      onClick={createSortHandler('total')}
                    >
                      Total Tokens Used
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((item, index) => (
                  <TableRow key={item.tokensUsed._id || index}>
                    <TableCell>
                      {new Date(item.timestamp).toLocaleString()}
                    </TableCell>
                    <TableCell>{item.index}</TableCell>
                    <TableCell>{item.tokensUsed.prompt}</TableCell>
                    <TableCell>{item.tokensUsed.completion}</TableCell>
                    <TableCell>{item.tokensUsed.total}</TableCell>
                  </TableRow>
                ))}
                {sortedData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default TokensUsageDashboard;
